import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";

import userSlice from "./Slices/users/userSlice";

import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import machineSlice from "./Slices/machines/machineSlice";
import odfSlice from "./Slices/odfs/odfSlice";
import checklistSlice from "./Slices/checklists/checklistSlice";
import panneSlice from "./Slices/pannes/panneSlice";
import authSlice from "./Slices/auth/authSlice";
import interventionSlice from "./Slices/interventions/interventionSlice";
import historiqueSlice from "./Slices/historique/historiqueSlice";
import ticketSlice from "./Slices/tickets/ticketSlice";
import planningsSlice from "./Slices/plannings/planningsSlice";
import produitsSlice from "./Slices/produits/produitsSlice";

const persistConfig = {
  key: "ecopack",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: authSlice,
  users: userSlice,
  machines: machineSlice,
  odfs: odfSlice,
  checklist: checklistSlice,
  pannes: panneSlice,
  plannings: planningsSlice,
  tickets: ticketSlice,
  interventions: interventionSlice,
  historique: historiqueSlice,
  produits: produitsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// console.log('-------------> ', process.env.REACT_APP_NODE_ENV , ' <-------------')
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV === "developpement",
  middleware: [thunk],
  //middleware: [applyMiddleware(thunk), curryGetDefaultMiddleware()]
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
