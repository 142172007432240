import React from "react";

const Loader = () => {
  return (
    <div>
      <div id="load_screen">
        {" "}
        <div className="loader">
          {" "}
          <div className="loader-content">
            <div className="spinner-grow align-self-center"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
