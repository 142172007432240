import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import { useParams } from "react-router-dom";
import Loader from "../../_Layouts/Loader/Loader";
import { ListProduits, deleteProduitApi, findProduitsApi } from "../../_App/Redux/Slices/produits/produitsSlice";
import { ProduitType } from "../../_App/Types/Entites/ProduitType";
import ProduitForm from "./ProduitForm";

const ListProducts = ({ load, setLoad }: any) => {
    const dispatch = useAppDispatch();
    const produits = useAppSelector(ListProduits);

    const [ShowModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<
        ProduitType | undefined
    >(undefined);
    const deleteProduct = async (code: any) => {
        let res = await dispatch(deleteProduitApi(code));
        if (res.payload.id) {
            setLoad(!load);
        }
    };
    return (
        <div className="searchable-container">
            <ProduitForm
                ShowModal={ShowModal}
                setShowModal={setShowModal}
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                load={load}
                setLoad={setLoad}
            />

            <div className="row layout-top-spacing">
                <div className="row">
                    <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 filtered-list-search layout-spacing align-self-center mb-0">
                        <form className="form-inline my-2 my-lg-0">
                            <div className="">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-search"
                                >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                </svg>
                                <input
                                    type="text"
                                    className="form-control product-search"
                                    id="input-search"
                                    placeholder="Search"
                                />
                            </div>
                        </form>
                    </div>

                    <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-sm-right text-center layout-spacing align-self-center">
                        <div className="d-flex justify-content-sm-end justify-content-center">
                            <i
                                className="fas fa-plus-square feather feather-user-plus"
                                id="btn-add-contact"
                                onClick={() => setShowModal(true)}
                            ></i>
                        </div>
                    </div>
                </div>
                {produits &&
                    produits.map((item, index) => (
                        <div
                            className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col layout-spacing"
                            key={index}
                        >
                            <div className="color-box">
                                <span
                                    className="cl-example"
                                    style={{
                                        backgroundImage: `url(${process.env.PUBLIC_URL}/assets/assets/img/no-image.jpg)`,
                                        backgroundSize: "contain",
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                    }}
                                ></span>
                                <div className="cl-info">
                                    <h5 className="cl-title">{item.libelle}</h5>
                                    <span>
                                        {/* {item.date_instalation.toString().split("T")[0]} */}
                                    </span>
                                </div>
                                <div
                                    className="cl-info"
                                    style={{
                                        justifyContent: "center",
                                        alignItems: "center",
                                        display: "flex",
                                    }}
                                >
                                    <button
                                        className="btn btn-primary action-btn"
                                        onClick={() => {
                                            setSelectedProduct(item);
                                            setShowModal(true);
                                        }}
                                    >
                                        <i className="fas fa-edit"></i>{" "}
                                    </button>
                                    <button
                                        className="btn btn-danger action-btn"
                                        onClick={() => deleteProduct(item.code)}
                                    >
                                        <i className="fas fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>

        </div>
    );
};

export default ListProducts;
