import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ProdPanning,
  updateProgramProd,
} from "../../_App/Redux/Slices/plannings/planningsSlice";
import { Form, Pagination, Table } from "react-bootstrap";
import FileInput from "./FileInput";
import EditableCell from "./EditableTableCell";

const ProdPrograms = ({ reload, setReload }: any) => {
  const dispatch = useAppDispatch();
  const prodPrograms = useAppSelector(ProdPanning);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");

  // Filtering data based on search term
  const filteredData = prodPrograms.filter((item) =>
    item.machine_code?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  // Pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Pagination
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, startPage + 2);

    while (endPage - startPage < 2) {
      if (startPage > 1) {
        startPage--;
      } else {
        endPage++;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => setCurrentPage(i)}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pageNumbers;
  };

  const updateDatabase = async (id: number, newValue: string) => {
    try {
      const res = await dispatch(
        updateProgramProd({ id: id, newData: newValue })
      );
      console.log(res);
    } catch (error) {
      console.error("Failed to update the database", error);
    }
  };

  const handleSave = (id: number, field: string, newValue: any) => {
    updateDatabase(id, newValue);
  };

  return (
    <div>
      <Form.Group controlId="formSearch">
        {prodPrograms && prodPrograms.length > 0 ? (
          <Form.Control
            type="text"
            placeholder="Recherche par machine ..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        ) : null}
        <FileInput type="Curatif" reload={reload} setReload={setReload} />
      </Form.Group>{" "}
      {prodPrograms && prodPrograms.length > 0 ? (
        <div style={{ overflow: "auto" }}>
          {" "}
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Machine</th>
                <th>Date</th>
                <th>Cliente</th>
                <th>ODF</th>
                <th>Cloture</th>
              </tr>
            </thead>
            <tbody>
              {currentItems?.map((item) => (
                <tr key={item.id}>
                  <EditableCell
                    value={item.machine_code}
                    onSave={(newValue) =>
                      handleSave(item.id, "machine_code", {
                        machine_code: newValue,
                      })
                    }
                    type={null}
                  />
                  <EditableCell
                    value={item.date_prod?.toString() || ""}
                    onSave={(newValue) =>
                      handleSave(item.id, "date_prod", { date_prod: newValue })
                    }
                    type={"date"}
                  />

                  <EditableCell
                    value={item.Cliente}
                    onSave={(newValue) =>
                      handleSave(item.id, "Cliente", { Cliente: newValue })
                    }
                    type={null}
                  />

                  <EditableCell
                    value={item.odf_code}
                    onSave={(newValue) =>
                      handleSave(item.id, "odf_code", { odf_code: newValue })
                    }
                    type={null}
                  />
                  <EditableCell
                    value={item.cloture}
                    onSave={(newValue) =>
                      handleSave(item.id, "cloture", {
                        cloture: newValue,
                      })
                    }
                    type={"switch"}
                  />
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : null}
      {/* {prodPrograms} */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Pagination>
          {/* Previous Button */}
          <Pagination.Prev onClick={handlePrevPage} />
          {/* Page Numbers */}
          {renderPageNumbers()}
          {/* Next Button */}
          <Pagination.Next onClick={handleNextPage} />
        </Pagination>
      </div>
    </div>
  );
};

export default ProdPrograms;
