import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { TicketType } from "../../../Types/Entites/TicketType";

export const findTickets = createAsyncThunk(
  "ticket/findTickets",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`ticket/findTickets`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTicketsImage = createAsyncThunk(
  "ticket/findticketsImages",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`ticket/findticketsImages`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateTicket = createAsyncThunk(
  "ticket/updateTicket",
  async ({ id, newData }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`ticket/updateTicket/${id}`, newData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const affectUser = createAsyncThunk(
  "ticket/affectUser",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`ticket/affectUser/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const sendAffectationTicket = createAsyncThunk(
  "ticket/notif",
  async ({ role, text }: any, { rejectWithValue }) => {
    try {
      console.log(role, text);
      const response = await axios.post(`ticket/notif/${role}`, text);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Tickets {
  ticketsList: TicketType[];
  ticketsGroupedByDate: { [date: string]: any[] };
  ticketsGroupedByCode: { [code: string]: any[] };
  imagesGroupedByTicket: { [ticket: string]: any[] };
}

const initialState: Tickets = {
  ticketsList: [],
  ticketsGroupedByDate: {},
  imagesGroupedByTicket: {},
  ticketsGroupedByCode: {},
};

export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findTickets.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "date");
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        ticketsList: action.payload,
        ticketsGroupedByDate: grouped,
        ticketsGroupedByCode: groupedByCode,
      };
    });
    builder.addCase(findTicketsImage.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "code");
      return {
        ...state,
        imagesGroupedByTicket: grouped,
      };
    });
    builder.addCase(affectUser.fulfilled, (state, action) => {
      const index = state.ticketsList.findIndex(
        (ticket) => ticket.id === action.payload.id
      );

      if (index !== -1) {
        const updatedListTickets = [...state.ticketsList];
        updatedListTickets[index] = action.payload;

        return {
          ...state,
          ticketsList: updatedListTickets,
        };
      }
      return state;
    });
  },
});

export const TicketsList = (state: RootState) => state.tickets.ticketsList;
export const TicketsPerDates = (state: RootState) =>
  state.tickets.ticketsGroupedByDate;
export const TicketsByCode = (state: RootState) =>
  state.tickets.ticketsGroupedByCode;
export const ImagesTickets = (state: RootState) =>
  state.tickets.imagesGroupedByTicket;

export default ticketSlice.reducer;
