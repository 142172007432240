import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  createPlaningItem,
  MachineProgsByDate,
  updatePlaningMachine,
} from "../../_App/Redux/Slices/plannings/planningsSlice";
import { MachinePlanningType } from "../../_App/Types/Entites/PlanningType";
import { ListUsers } from "../../_App/Redux/Slices/users/userSlice";
import { ListMachines } from "../../_App/Redux/Slices/machines/machineSlice";
import { generateCode } from "../../_App/Helpers/helpers";

const EventModal = ({
  ShowModal,
  setShowModal,
  event,
  setEvent,
  increaseRand,
  date,
  setDate,
}: any) => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(ListUsers);
  const [machine, setMachine] = useState();
  const [user, setUser] = useState();
  const machines = useAppSelector(ListMachines);
  const plannings = useAppSelector(MachineProgsByDate);

  var defaultValues = {
    user_code: event && event.user_code ? event.user_code : "",
    machine_code: event && event.machine_code ? event.machine_code : "",
    date: event && event.date ? event.date : "",
    User: event && event.User ? event.User : {},
    Machine: event && event.User ? event.User : {},
    code: event && event.code ? event.code : "",
  };
  const { register, handleSubmit, reset } = useForm<MachinePlanningType>({
    defaultValues: defaultValues,
  });
  const onSubmit = async (data: any) => {
    try {
      if (!event && date) {
        await dispatch(createPlaningItem(data));
      } else {
        await dispatch(updatePlaningMachine({ id: event.id, newData: data }));
      }
      increaseRand();
      setShowModal(false);
    } catch (error) {}
  };
  useEffect(() => {
    if (event) {
      reset({
        user_code: defaultValues.user_code,
        machine_code: defaultValues.machine_code,
        date: date ? date : defaultValues.date,
      });
    } else {
      reset({
        code: generateCode(Object.values(plannings), ""),
        user_code: "",
        machine_code: "",
        date: date,
      });
    }
  }, [event, ShowModal]);

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => {
          setEvent(undefined);
          setDate(undefined);
          setShowModal(false);
        }}
        centered
      >
        <div className="modal-body">
          <span
            className="close"
            onClick={() => {
              setDate(undefined);
              setEvent(undefined);
              setShowModal(false);
            }}
            style={{ cursor: "pointer" }}
          >
            &times;
          </span>
          <div className="add-edit-event-box">
            <div className="add-edit-event-content">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-12">
                    <label className="">Technicien</label>
                    <div className="d-flex event-title">
                      <select
                        className="form-control"
                        {...register("user_code")}
                      >
                        {users &&
                          users.map((user) => (
                            <option
                              key={user.code} // Use a unique identifier as key
                              value={user.code}
                              defaultValue={
                                user.code === defaultValues.user_code
                                  ? user.code.toString()
                                  : ""
                              }
                              onChange={() =>
                                console.log(user.code, defaultValues.user_code)
                              }
                            >
                              {user.nom}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="form-group start-date">
                      <label className="">Machine</label>
                      <div className="d-flex">
                        <select
                          className="form-control"
                          {...register("machine_code")}
                        >
                          {machines &&
                            machines.map((machine) => (
                              <option
                                key={machine.code} // Use a unique identifier as key
                                value={machine.code}
                                defaultValue={
                                  machine.code === defaultValues.machine_code
                                    ? machine.code.toString()
                                    : ""
                                }
                              >
                                {machine.nom}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  {!date ? (
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="form-group start-date">
                        <label className="">Date</label>
                        <div className="d-flex">
                          <input
                            id="start-date"
                            placeholder="Start Date"
                            className="form-control"
                            type="date"
                            defaultValue={defaultValues.date}
                            {...register("date")}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {!date ? (
                    <div className="col-md-12 col-sm-12 col-12">
                      <button
                        className="btn"
                        style={{ width: "100%" }}
                        onClick={() => {
                          window.location.href =
                            "/preventifChecklists/" +
                            defaultValues.code +
                            "/" +
                            defaultValues.machine_code;
                        }}
                      >
                        Resultat checklist{" "}
                        <i className="fas fa-external-link-alt"></i>
                      </button>
                    </div>
                  ) : null}
                </div>
                <Modal.Footer>
                  <div className="modal-footer">
                    <button id="discard" className="btn" data-dismiss="modal">
                      Annuler
                    </button>
                    <button type="submit" className="btn">
                      Enregistrer
                    </button>
                  </div>
                </Modal.Footer>
              </form>{" "}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventModal;
