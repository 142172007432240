import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../_App/Redux/hooks";
import { Historique } from "../../_App/Redux/Slices/historique/historiqueSlice";
import moment from "moment";
import Ticket from "../Tickets/Ticket";
import { InterventionType } from "../../_App/Types/Entites/InterventionType";
import { TicketType } from "../../_App/Types/Entites/TicketType";
import FicheInterventionModal from "../Interventions/FicheInterventionModal";
import { InterventionParCode } from "../../_App/Redux/Slices/interventions/interventionSlice";
import { TicketsByCode } from "../../_App/Redux/Slices/tickets/ticketSlice";
import {
  MachineProgsByDate,
  ProgsProd,
} from "../../_App/Redux/Slices/plannings/planningsSlice";
import { ProdPlanningType } from "../../_App/Types/Entites/PlanningType";
import _ from "lodash";

const MachineHistorique = () => {
  let { code } = useParams();
  const machineHistorique = useAppSelector(Historique);
  const interventions = useAppSelector(InterventionParCode);
  const tickets = useAppSelector(TicketsByCode);

  const progs = useAppSelector(MachineProgsByDate);
  const today = moment().startOf("day");
  const nextWeek = moment().add(1, "week").startOf("day");
  const [startDate, setStartDate] = useState<any>(today); // Default to start of today
  const [endDate, setEndDate] = useState<any>(nextWeek); // Default to end of today
  const [filteredOdfs, setFilteredOdf] = useState<{
    [date: string]: any[];
  }>({});
  const [selectedOdf, setSelectedOdf] = useState<string>();
  useEffect(() => {
    console.log(machineHistorique);
    const odfsGroupedByLastHistoryDate = Object.entries(machineHistorique).map(
      ([odf_code, histories]: any) => {
        // The most recent date is the first one in the ordered list
        const lastDate = histories[0].date;

        // Return an object with the ODF and the last date
        return { odf_code, date: lastDate.toString().split("T")[0] };
      }
    );
    const groupedByDate = _.groupBy(odfsGroupedByLastHistoryDate, "date");

    console.log("our new list", groupedByDate);
    if (groupedByDate && Object.entries(groupedByDate)) {
      let filteredObject = { ...groupedByDate };
      Object.entries(groupedByDate).map(([key, value]: any) => {
        const filteredValues = value.filter(
          (prog: any) =>
            moment(prog?.date.toString().split("T")[0]).isSameOrAfter(
              startDate,
              "day"
            ) &&
            moment(prog?.date.toString().split("T")[0]).isSameOrBefore(
              endDate,
              "day"
            )
        );
        filteredObject[key] = filteredValues;
      });

      setFilteredOdf(filteredObject);
    }
    setSelectedOdf(
      groupedByDate[new Date().toISOString().split("T")[0]]
        ? groupedByDate[new Date().toISOString().split("T")[0]][0]?.odf_code
        : ""
    );
  }, [startDate, endDate, progs]);

  const actif = () => {
    let sumActif = 0;
    let sumInactif = 0;
    if (code && machineHistorique[code]) {
      machineHistorique[code].forEach((item) => {
        if (item.actif === "1") {
          sumActif += 1;
        } else {
          sumInactif += 1;
        }
      });
      sumActif = (sumActif * machineHistorique[code].length) / 100;
      sumInactif = (sumInactif * machineHistorique[code].length) / 100;
    }
    return { sumActif, sumInactif };
  };

  const machine =
    code && machineHistorique[code]
      ? machineHistorique[code][0].Machine
      : undefined;
  const [showIntervention, setShowIntervention] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState<
    InterventionType | undefined
  >(undefined);
  const [showTicket, setShowTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketType | undefined>(
    undefined
  );
  return (
    <div>
      <Ticket
        ShowModal={showTicket}
        setShowModal={setShowTicket}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        setSelectedIntervention={setSelectedIntervention}
        setShowIntervention={setShowIntervention}
      />
      <FicheInterventionModal
        ShowModal={showIntervention}
        setShowModal={setShowIntervention}
        intervention={selectedIntervention}
        setIntervention={setSelectedIntervention}
      />
      <div className="row layout-top-spacing">
        <div id="timelineProfile" className="col-lg-12 layout-spacing">
          <div className="statbox widget box box-shadow">
            <div className="widget-content widget-content-area row">
              <div className="timeline-simple col-6">
                <p
                  className="timeline-title"
                  onClick={() => console.log(selectedOdf)}
                >
                  Historique de la machine {machine?.nom}{" "}
                </p>

                <div className="timeline-list">
                  {selectedOdf &&
                    machineHistorique &&
                    machineHistorique[selectedOdf] &&
                    Object.values(machineHistorique[selectedOdf]).map(
                      (item, index) => (
                        <div className="timeline-post-content" key={index}>
                          <div className="user-profile">
                            <i className="fas fa-circle"></i>
                            {/* <img
                              src={
                                item.operation === "Démarrage Production"
                                  ? process.env.PUBLIC_URL +
                                    "/assets/assets/img/dot.png"
                                  : item.operation === "Intervention"
                                  ? process.env.PUBLIC_URL +
                                    "/assets/assets/img/dot-warn.png"
                                  : process.env.PUBLIC_URL +
                                    "/assets/assets/img/dot-danger.png"
                              }
                              alt=""
                            /> */}
                          </div>
                          <div className="" style={{ marginBottom: "0px" }}>
                            <h4>{item.date.toString().split("T")[0]}</h4>{" "}
                            {item.actif === "0" ? (
                              <span className="badge badge-danger">
                                {" "}
                                Inactif{" "}
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                {" "}
                                Actif{" "}
                              </span>
                            )}
                            <p className="meta-time-date">
                              {item.heure.toString()}
                            </p>
                            <div className="">
                              {item.operation === "Démarrage Production" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-check-circle text-success"
                                  style={{ marginRight: "5px" }}
                                >
                                  <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                  <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                              ) : item.operation === "Arrêt Production" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x-circle text-danger"
                                  style={{ marginRight: "5px" }}
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                              ) : (
                                <i
                                  className="fas fa-tools"
                                  style={{ marginRight: "5px" }}
                                ></i>
                              )}
                              <h6 className="">{item.operation}</h6>
                              <p className="post-text mb-0">
                                Par {item.User?.nom}{" "}
                              </p>
                              <p
                                className="post-text"
                                style={{
                                  textDecoration: "underline",
                                  color: "#4361ee",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    item.Intervention &&
                                    interventions[item.code.toString()]
                                  ) {
                                    setSelectedIntervention(
                                      interventions[item.code.toString()][0]
                                    );
                                    setShowIntervention(true);
                                  }
                                  if (
                                    item.Ticket &&
                                    tickets[item.code.toString()]
                                  ) {
                                    setSelectedTicket(
                                      tickets[item.code.toString()][0]
                                    );
                                    setShowTicket(true);
                                  }
                                }}
                              >
                                {item.Intervention
                                  ? "Intervention"
                                  : item.Ticket
                                  ? "Ticket"
                                  : null}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                </div>
              </div>
              <div className="timeline-simple col-6">
                <p className="timeline-title">ODFs {machine?.nom} </p>
                <div className="row">
                  <div
                    className="col-6 pb-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label htmlFor="start-date"></label>
                    <input
                      className="date-input"
                      type="date"
                      id="start-date"
                      value={startDate.format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setStartDate(
                          moment(e.currentTarget.value, "YYYY-MM-DD")
                        )
                      }
                    />
                  </div>
                  <div
                    className="col-6 pb-2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <label htmlFor="end-date"></label>
                    <input
                      className="date-input"
                      type="date"
                      id="end-date"
                      value={endDate.format("YYYY-MM-DD")}
                      onChange={(e) =>
                        setEndDate(moment(e.currentTarget.value, "YYYY-MM-DD"))
                      }
                    />
                  </div>
                </div>
                <div className="widget-content searchable-container list">
                  <div className="row layout-spacing">
                    <div className="col-lg-12">
                      <div className="statbox widget box box-shadow">
                        <div className="widget-content widget-content-area">
                          <div className="table-responsive mb-4">
                            <table
                              id="style-3"
                              className="table style-3  table-hover"
                            >
                              <thead>
                                <tr>
                                  <th onClick={() => console.log(filteredOdfs)}>
                                    Date
                                  </th>
                                  <th>Odf</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Object.entries(filteredOdfs).map(
                                  ([key, value]) => {
                                    return (
                                      <React.Fragment key={key}>
                                        {value.length > 0 ? (
                                          <>
                                            <tr>
                                              <td rowSpan={value.length + 1}>
                                                {(() => {
                                                  // Convert key string to Date object
                                                  var keyDate = new Date(key);
                                                  // Get current date
                                                  var currentDate = new Date();

                                                  // Set time part of both dates to midnight
                                                  keyDate.setHours(0, 0, 0, 0);
                                                  currentDate.setHours(
                                                    0,
                                                    0,
                                                    0,
                                                    0
                                                  );

                                                  // Compare dates
                                                  return keyDate.getTime() ===
                                                    currentDate.getTime()
                                                    ? "Aujourd'hui"
                                                    : key;
                                                })()}
                                              </td>
                                            </tr>
                                            {value.map((item, index) => (
                                              <tr key={index}>
                                                <td
                                                  style={{
                                                    textDecoration: "underline",
                                                    color: "#4361ee",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    setSelectedOdf(
                                                      item.odf_code
                                                    )
                                                  }
                                                >
                                                  {item.odf_code ===
                                                  selectedOdf ? (
                                                    <mark>
                                                      {item.Odf?.libelle
                                                        ? item.Odf?.libelle
                                                        : item.odf_code}
                                                    </mark>
                                                  ) : item.Odf?.libelle ? (
                                                    item.Odf?.libelle
                                                  ) : (
                                                    item.odf_code
                                                  )}{" "}
                                                </td>
                                              </tr>
                                            ))}
                                          </>
                                        ) : null}
                                      </React.Fragment>
                                    );
                                  }
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineHistorique;
