import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { Modal } from "react-bootstrap";
import { PanneType } from "../../_App/Types/Entites/PanneType";
import { savePanneApi, updatePanneByCode } from "../../_App/Redux/Slices/pannes/panneSlice";
import { saveProduitApi, updateProduitById } from "../../_App/Redux/Slices/produits/produitsSlice";
import { ProduitType } from "../../_App/Types/Entites/ProduitType";

const ProduitForm = ({
  ShowModal,
  setShowModal,
  selectedProduct,
  setSelectedProduct,
  load,
  setLoad,
}: any) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ProduitType>();

  const defaultValues = {
    libelle: selectedProduct && selectedProduct.libelle ? selectedProduct.libelle : "",
    type: selectedProduct && selectedProduct.type ? selectedProduct.type : "",
    code: selectedProduct && selectedProduct.code ? selectedProduct.code : "",

  };

  const closeModal = () => {
    if (setSelectedProduct !== null) setSelectedProduct(undefined);
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedProduct) {
      reset({
        libelle: selectedProduct.libelle,
        type: selectedProduct.type,
        code: selectedProduct.code
      });
    } else {
      reset({
        libelle: "",
        type: "",
        code: ""
      });
    }
  }, [selectedProduct, ShowModal]);

  const onSubmit: SubmitHandler<ProduitType> = async (data) => {
    if (selectedProduct) {
      try {
        await dispatch(
          updateProduitById({ code: selectedProduct.code, data: data })
        );
        console.log("Panne updated successfully!");
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating panne:", error);
      }
    } else {
      let res = await dispatch(saveProduitApi(data));
      if (res.payload.success) {
        setLoad(!load);
        closeModal();
      }
    }
  };

  return (
    <div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={ShowModal}
        onHide={() => setShowModal(false)}
      >
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addPanneLabel" onClick={() => console.log(selectedProduct)}>
              {selectedProduct
                ? "Edit Panne " + selectedProduct.libelle
                : "New Panne"}
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              if (setSelectedProduct !== null) setSelectedProduct(undefined);
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form id="addPanneModalTitle" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-panne-box">
                <div className="add-panne-content">
                  {!selectedProduct ? <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="panne-name">
                        <i className="flaticon-user-11"></i>
                        <input
                          type="text"
                          id="p-name"
                          className="form-control"
                          placeholder="Code"
                          defaultValue={defaultValues.code}
                          {...register("code", {
                            required: "code is required",
                          })}
                        />
                        <span className="validation-text">{errors.code?.message}</span>
                      </div>
                    </div>

                  </div> : null}

                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="panne-name">
                        <i className="flaticon-user-11"></i>
                        <input
                          type="text"
                          id="p-name"
                          className="form-control"
                          placeholder="Libelle"
                          defaultValue={defaultValues.libelle}
                          {...register("libelle", {
                            required: "libelle is required",
                          })}
                        />
                        <span className="validation-text">{errors.libelle?.message}</span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-phone">
                        <i className="flaticon-telephone"></i>
                        <select
                          className="form-control mb-2"
                          {...register("type", {
                            required: "type is required",
                          })}
                        >
                          <option>{defaultValues.type}</option>

                          <option value={"DEFAULT"}> DEFAUT</option>
                          <option value={"PANNE"}> PANNE </option>
                          <option value={"AUTRES"}> AUTRES </option>
                        </select>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="searchable-container modal-footer">
              <button
                className="btn btn-warning"
                data-dismiss="modal"
                type="reset"
                onClick={() => closeModal()}
              >
                <i className="flaticon-delete-1"></i> Annuler
              </button>

              <button type="submit" id="btn-add" className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};

export default ProduitForm;
