import React, { useEffect } from "react";
import Machines from "../../Components/Machines/Machines";
import Navbar from "../../_Layouts/Navbar/Navbar";
import MachinesList from "../../Components/Machines/MachinesList";
import { findOdfs } from "../../_App/Redux/Slices/odfs/odfSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";

const MachinesListPage = () => {
  
  return (
    <div>
      <Navbar titre={"Liste des machines"} />
      <div className="page-container">
        <MachinesList />{" "}
      </div>
    </div>
  );
};

export default MachinesListPage;
