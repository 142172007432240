// Inside UsersPage.jsx
import React from "react";
import Users from "../../Components/Users/Users";
import Navbar from "../../_Layouts/Navbar/Navbar";

const UsersPage = () => {
  return (
    <div>
      <Navbar titre={"Users"} />
      <div className="page-container">
        <Users />
      </div>
    </div>
  );
};

export default UsersPage;
