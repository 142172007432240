import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListRoles,
  saveUserAPi,
  updateUser,
} from "../../_App/Redux/Slices/users/userSlice";
import { Modal } from "react-bootstrap";

const UserForm = ({
  selectedUser,
  setSelectedUser,
  ShowModal,
  setShowModal,
  load,
  setLoad,
}: any) => {
  const dispatch = useAppDispatch();
  var defaultValues = {
    nom: selectedUser && selectedUser.nom ? selectedUser.nom : "",
    prenom: selectedUser && selectedUser.prenom ? selectedUser.prenom : "",
    mobile: selectedUser && selectedUser.mobile ? selectedUser.mobile : "",
    role_code:
      selectedUser && selectedUser.role_code ? selectedUser.role_code : "",
    code: selectedUser && selectedUser.code ? selectedUser.code : "",
    matricule:
      selectedUser && selectedUser.matricule ? selectedUser.matricule : "",
    email: selectedUser && selectedUser.email ? selectedUser.email : "",
  };
  const roles = useAppSelector(ListRoles);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<UserDataType>({
    defaultValues: defaultValues,
  });
  const closeModal = () => {
    setSelectedUser(undefined);
    setShowModal(false);
  };

  useEffect(() => {
    if (selectedUser) {
      reset({
        nom: selectedUser.nom,
        prenom: selectedUser.prenom,
        mobile: selectedUser.mobile,
        role_code: selectedUser.role_code,
        code: selectedUser.code,
        matricule: selectedUser.matricule,
        email: selectedUser.email,
      });
    } else {
      reset({
        nom: "",
        prenom: "",
        mobile: "",
        role_code: "",
        code: "",
        matricule: "",
        email: "",
      });
    }
  }, [selectedUser, ShowModal]);

  const onSubmit: SubmitHandler<UserDataType> = async (data) => {
    if (selectedUser) {
      let response = await dispatch(
        updateUser({ code: selectedUser.code, data: data })
      );
      if (response.payload) {
        closeModal();
        setLoad(!load);
      }
    } else {
      let response = await dispatch(saveUserAPi(data));
      if (response.payload.success) {
        closeModal();
        setLoad(!load);
      }
    }
  };

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => {
          closeModal();
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span
              className="modal-title"
              id="addCategorieLabel"
              onClick={() => console.log(selectedUser)}
            >
              {" "}
              Modifier utilisateur
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              closeModal();
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <form id="addContactModalTitle" onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-name">
                        <i className="flaticon-user-11"></i>
                        <input
                          type="text"
                          id="c-name"
                          defaultValue={defaultValues.nom}
                          className="form-control mb-2"
                          placeholder="Nom"
                          {...register("nom", {
                            required: "name is required",
                          })}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-email">
                        <i className="flaticon-mail-26"></i>
                        <input
                          type="text"
                          id="c-email"
                          defaultValue={defaultValues.prenom}
                          className="form-control mb-2"
                          placeholder="Prenom"
                          {...register("prenom")}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-occupation">
                        <i className="flaticon-fill-area"></i>
                        <input
                          type="text"
                          id="c-occupation"
                          defaultValue={defaultValues.mobile}
                          className="form-control mb-2"
                          placeholder="Tél"
                          {...register("mobile")}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="contact-phone">
                        <i className="flaticon-telephone"></i>
                        <select className="form-control mb-2">
                          <option>{defaultValues.role_code}</option>
                          {roles &&
                            roles.map((role, index) => (
                              <option key={index}> {role.libelle} </option>
                            ))}
                        </select>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-occupation">
                        <i className="flaticon-fill-area"></i>
                        <input
                          type="text"
                          id="c-occupation"
                          defaultValue={defaultValues.code}
                          className="form-control mb-2"
                          placeholder="Code"
                          {...register("code")}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="contact-phone">
                        <i className="flaticon-telephone"></i>
                        <input
                          type="text"
                          id="c-phone"
                          defaultValue={defaultValues.matricule}
                          className="form-control mb-2"
                          placeholder="Matricule"
                          {...register("matricule")}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="contact-location">
                        <i className="flaticon-location-1"></i>
                        <input
                          type="text"
                          id="c-location"
                          defaultValue={defaultValues.email}
                          className="form-control mb-2"
                          placeholder="Email"
                          {...register("email")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn"
                type="reset"
                onClick={() => {
                  closeModal();
                }}
              >
                {" "}
                <i className="flaticon-delete-1"></i> Annuler
              </button>

              <button type="submit" id="btn-edit" className="float-left btn">
                Save
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserForm;
