import React from "react";
import Machines from "../../Components/Machines/Machines";
import Navbar from "../../_Layouts/Navbar/Navbar";
import MachineFiche from "../../Components/Machines/MachineFiche";

const MachinesFichePage = () => {
  return (
    <div>
      <Navbar titre={"Fiche machine"} />
      <div className="page-container">
        <MachineFiche />{" "}
      </div>
    </div>
  );
};

export default MachinesFichePage;
