import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findIntervention,
  findinterventionImages,
  findMouvements,
  findObjets,
  ListInterventions,
  ListObjets,
} from "../../_App/Redux/Slices/interventions/interventionSlice";
import FicheInterventionModal from "./FicheInterventionModal";
import { InterventionType } from "../../_App/Types/Entites/InterventionType";
import moment from "moment";
import {
  findTickets,
  findTicketsImage,
} from "../../_App/Redux/Slices/tickets/ticketSlice";

const Interventions = () => {
  const dispatch = useAppDispatch();
  const interventions = useAppSelector(ListInterventions);
  const objets = useAppSelector(ListObjets);
  const [ShowModal, setShowModal] = useState(false);
  const [selectedIntervention, setSelectedIntervention] = useState<
    InterventionType | undefined
  >(undefined);

  useEffect(() => {
    dispatch(findTickets(null));
    dispatch(findIntervention(null));
    dispatch(findinterventionImages(null));
    dispatch(findTicketsImage(null));
    dispatch(findObjets(null));
    dispatch(findMouvements(null));
  }, [dispatch]);

  const [startDate, setStartDate] = useState<any>(moment().startOf("day")); // Default to start of today
  const [endDate, setEndDate] = useState<any>(moment().endOf("day")); // Default to end of today
  const [filteredInterventions, setFilteredInterventions] = useState<any>([]);
  useEffect(() => {
    if (interventions && Object.entries(interventions)) {
      let filteredObject = { ...interventions };
      Object.entries(interventions).map(([key, value]) => {
        const filteredValues = value.filter(
          (intervention) =>
            moment(intervention?.date.toString().split("T")[0]).isSameOrAfter(
              startDate,
              "day"
            ) &&
            moment(intervention?.date.toString().split("T")[0]).isSameOrBefore(
              endDate,
              "day"
            )
        );
        filteredObject[key] = filteredValues;
      });

      setFilteredInterventions(filteredObject);
    }
  }, [startDate, endDate, interventions]);

  return (
    <div>
      <FicheInterventionModal
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        intervention={selectedIntervention}
        setIntervention={setSelectedIntervention}
      />
      <div className="row">
        <div
          className="col-3 pb-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label htmlFor="start-date"></label>
          <input
            className="date-input"
            type="date"
            id="start-date"
            value={startDate.format("YYYY-MM-DD")}
            onChange={(e) =>
              setStartDate(moment(e.currentTarget.value, "YYYY-MM-DD"))
            }
          />
        </div>
        <div
          className="col-3 pb-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <label htmlFor="end-date"></label>
          <input
            className="date-input"
            type="date"
            id="end-date"
            value={endDate.format("YYYY-MM-DD")}
            onChange={(e) =>
              setEndDate(moment(e.currentTarget.value, "YYYY-MM-DD"))
            }
          />
        </div>
      </div>
      <div className="widget-content searchable-container list">
        <div className="row layout-spacing">
          <div className="col-lg-12">
            <div className="statbox widget box box-shadow">
              <div className="widget-content widget-content-area">
                <div className="table-responsive mb-4">
                  <table id="style-3" className="table style-3  table-hover">
                    <thead>
                      <tr>
                        <th onClick={() => console.log(filteredInterventions)}>
                          Groupe
                        </th>
                        <th>Objets d'interventions</th>
                        <th>Interventions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(objets).map(([key, value]) => {
                        const groupe = value[0].InterventionGroupe?.libelle;
                        return (
                          <React.Fragment key={key}>
                            <tr>
                              <td rowSpan={value.length + 1}> {groupe} </td>
                            </tr>
                            {value.map((item, index) => (
                              <tr key={index}>
                                <td
                                  onClick={() => {
                                    if (
                                      filteredInterventions &&
                                      filteredInterventions[item.code] &&
                                      filteredInterventions[item.code].length
                                    ) {
                                      console.log(filteredInterventions);
                                      setSelectedIntervention(
                                        interventions[item.code][0]
                                      );
                                      setShowModal(true);
                                    }
                                  }}
                                >
                                  {" "}
                                  {filteredInterventions &&
                                  filteredInterventions[item.code] &&
                                  filteredInterventions[item.code].length ? (
                                    <mark>{item.libelle}</mark>
                                  ) : (
                                    item.libelle
                                  )}{" "}
                                </td>
                                <td>
                                  <div className="user-phone col-2">
                                    {filteredInterventions &&
                                      filteredInterventions[item.code] &&
                                      filteredInterventions[item.code].map(
                                        (item1: any, index: any) => (
                                          <span
                                            key={index}
                                            onClick={() => {
                                              setSelectedIntervention(item1);
                                              setShowModal(true);
                                            }}
                                            className="usr-ph-no"
                                            style={{
                                              textDecoration: "underline",
                                              color: "#4361ee",
                                              cursor: "pointer",
                                            }}
                                          >
                                            {" "}
                                            {item1.InterventionAction
                                              ? item1.InterventionAction
                                                  ?.libelle
                                              : "Intervention"}{" "}
                                          </span>
                                        )
                                      )}
                                  </div>{" "}
                                </td>
                              </tr>
                            ))}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Interventions;
