import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import {
  ListMachines,
  ListMachinesPerOdfs,
  deleteMachineApi,
  findMachinesApi,
} from "../../_App/Redux/Slices/machines/machineSlice";
import MchineForm from "./MchineForm";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import { useParams } from "react-router-dom";
import Loader from "../../_Layouts/Loader/Loader";

const Machines = () => {
  const dispatch = useAppDispatch();
  const machinesODF = useAppSelector(ListMachinesPerOdfs);
  const machines = useAppSelector(ListMachines);
  const [load, setLoad] = useState(true);

  const [ShowModal, setShowModal] = useState(false);
  const [selectedMachine, setSelectedMAchine] = useState<
    MachineType | undefined
  >(undefined);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before making API calls

        // Dispatch API calls
        await dispatch(findMachinesApi(null));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, load]);

  const deleteMachine = async (code: any) => {
    let res = await dispatch(deleteMachineApi(code));
    if (res.payload.id) {
      setLoad(!load);
    }
  };
  return (
    <div className="searchable-container">
      <MchineForm
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        selectedMachine={selectedMachine}
        setSelectedMAchine={setSelectedMAchine}
        load={load}
        setLoad={setLoad}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className="row layout-top-spacing">
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 filtered-list-search layout-spacing align-self-center mb-0">
              <form className="form-inline my-2 my-lg-0">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control product-search"
                    id="input-search"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-sm-right text-center layout-spacing align-self-center">
              <div className="d-flex justify-content-sm-end justify-content-center">
                <i
                  className="fas fa-plus-square feather feather-user-plus"
                  id="btn-add-contact"
                  onClick={() => setShowModal(true)}
                ></i>
              </div>
            </div>
          </div>
          {machines &&
            machines.map((item, index) => (
              <div
                className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col layout-spacing"
                key={index}
              >
                <div className="color-box">
                  <span
                    className="cl-example"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/assets/img/machine.png)`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  ></span>
                  <div className="cl-info">
                    <h5 className="cl-title">{item.code}</h5>
                    <span>
                      {/* {item.date_instalation.toString().split("T")[0]} */}
                    </span>
                  </div>
                  <div
                    className="cl-info"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <button
                      className="btn btn-primary action-btn"
                      onClick={() => {
                        setSelectedMAchine(item);
                        setShowModal(true);
                      }}
                    >
                      <i className="fas fa-edit"></i>{" "}
                    </button>
                    <button
                      className="btn btn-danger action-btn"
                      onClick={() => deleteMachine(item.code)}
                    >
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Machines;
