import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import Loader from "../../_Layouts/Loader/Loader";

import ChecklistForm from "./ChecklistForm";
import { ChecklistType } from "../../_App/Types/Entites/ChecklistType";
import {
  Groups,
  checklistsCuratifGroupedByGroupe,
  findChecklistsGroups,
  updateChecklistItemApi,
} from "../../_App/Redux/Slices/checklists/checklistSlice";

interface ODFData {
  [odfCode: string]: {
    [machineCode: string]: ChecklistType[];
  };
}

const Checklist = () => {
  const dispatch = useAppDispatch();
  const checklists = useAppSelector(checklistsCuratifGroupedByGroupe);

  const [editingIndex, setEditingIndex] = useState<number | null>(null); // State to keep track of the index being edited
  const [editedValue, setEditedValue] = useState<String>(""); // State to keep track of the edited value

  const handleEditStart = (index: number, libelle: String) => {
    setEditingIndex(index);
    setEditedValue(libelle);
  };

  const handleEditChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedValue(event.target.value);
  };

  const handleEditBlur = (code: String) => {
    if (editingIndex !== null) {
      onUpdateChecklistItem(code, editedValue); // Assuming updateChecklistItemApi expects an id and date
      setEditingIndex(null);
    }
  };
  const [ShowModal, setShowModal] = useState(false);

  const [loading, setLoading] = useState(false);
 

  const onUpdateChecklistItem = async (code: String, date: any) => {
    const response = await dispatch(updateChecklistItemApi({ code, date }));
  };

  return (
    <div className="searchable-container">
      <ChecklistForm showModal={ShowModal} setShowModal={setShowModal} />
      {loading ? (
        <Loader />
      ) : (
        <div className="widget-content searchable-container list">
          <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 filtered-list-search layout-spacing align-self-center mb-0">
              <form className="form-inline my-2 my-lg-0">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-search"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                  <input
                    type="text"
                    className="form-control product-search"
                    id="input-search"
                    placeholder="Search"
                  />
                </div>
              </form>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-sm-right text-center layout-spacing align-self-center">
              <div className="d-flex justify-content-sm-end justify-content-center">
                <i
                  className="fas fa-plus-square feather feather-user-plus"
                  id="btn-add-contact"
                  onClick={() => setShowModal(true)}
                ></i>
              </div>
            </div>
          </div>
          <div className="row layout-top-spacing">
            <div
              className="widget-content widget-content-area"
              style={{ padding: 0 }}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                  {Object.entries(checklists).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <thead>
                        <tr>
                          <th colSpan={4} style={{ textAlign: "center" }}>
                            {" "}
                            {key}{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {value &&
                          value.map((item, index) => (
                            <tr key={index}>
                              <td
                                onClick={() =>
                                  handleEditStart(index, item.libelle)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {editingIndex === index ? (
                                  <input
                                    type="text"
                                    value={editedValue.toString()}
                                    onChange={handleEditChange}
                                    onBlur={() => handleEditBlur(item.code)}
                                  />
                                ) : (
                                  index + 1
                                )}
                              </td>
                              <td colSpan={2}>
                                {editingIndex === index ? (
                                  <input
                                    type="text"
                                    value={editedValue.toString()}
                                    onChange={handleEditChange}
                                    onBlur={() => handleEditBlur(item.code)}
                                  />
                                ) : (
                                  item.libelle
                                )}
                              </td>
                              <td
                                colSpan={2}
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              >
                                <i
                                  className="fas fa-times-circle"
                                  style={{ color: "#e7515a" }}
                                  onClick={() => console.log(index)}
                                ></i>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </React.Fragment>
                  ))}
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Checklist;
