// Inside UsersPage.jsx
import React, { useEffect, useState } from "react";
import Users from "../../Components/Users/Users";
import Navbar from "../../_Layouts/Navbar/Navbar";
import Pannes from "../../Components/Pannes/Pannes";
import { useAppDispatch } from "../../_App/Redux/hooks";
import Loader from "../../_Layouts/Loader/Loader";
import { findProduitsApi } from "../../_App/Redux/Slices/produits/produitsSlice";
import ListProducts from "../../Components/Produits/ListProduits";

const ProductsPage = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(true)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await dispatch(findProduitsApi(null));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, load]);
  return !loading ? (
    <div>
      <Navbar titre={"Piéces de rechange"} />
      <div className="page-container">
        <ListProducts load={load} setLoad={setLoad} />
      </div>
    </div>
  ) : (
    <Loader />
  );
};

export default ProductsPage;
