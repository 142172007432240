import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  deletePanneApi,
  panneByType,
  PannesList,
} from "../../_App/Redux/Slices/pannes/panneSlice";
import PanneForm from "./PanneForm";
import { PanneType } from "../../_App/Types/Entites/PanneType";

const Pannes = ({ load, setLoad }: any) => {
  const dispatch = useAppDispatch();
  const pannes = useAppSelector(panneByType);
  const [showModal, setShowModal] = useState(false);
  const [selectedPanne, setSelectedPanne] = useState<
    PanneType | undefined
  >(undefined);

  const deletePanne = async (code: any) => {
    let res = await dispatch(deletePanneApi(code));
    if (res.payload.id) {
      setLoad(!load);
    }
  };
  return (
    <div className="searchable-container">
      <div className="row layout-top-spacing">
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 filtered-list-search layout-spacing align-self-center mb-0">
            <form className="form-inline my-2 my-lg-0">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input
                  type="text"
                  className="form-control product-search"
                  id="input-search"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-sm-right text-center layout-spacing align-self-center">
            <div className="d-flex justify-content-sm-end justify-content-center">
              <i
                className="fas fa-plus-square feather feather-user-plus"
                id="btn-add-contact"
                onClick={() => setShowModal(true)}
              ></i>
            </div>
          </div>
          <PanneForm
            ShowModal={showModal}
            setShowModal={setShowModal}
            selectedPanne={selectedPanne}
            setSelectedPanne={setSelectedPanne}
            load={load}
            setLoad={setLoad}
          />
        </div>
        {pannes && (
          <>
            {Object.entries(pannes).map(([key, value]) => (
              <div className="row" key={key}>
                <h3>{key}</h3> {/* Title with the key */}
                {value &&
                  value.map((item, index) => (
                    <div
                      className="col-xl-4 col-lg-6 col-md-4 col-sm-6 col layout-spacing"
                      key={key + "-" + index} // Ensure unique key
                    >
                      <div className="color-box">
                        <span
                          className="cl-example"
                          style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/assets/img/no-image.jpg)`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                          }}
                        ></span>
                        <div className="cl-info">
                          <h5 className="cl-title">{item.libelle}</h5>
                          <span></span>
                        </div>
                        <div
                          className="cl-info"
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <button
                            className="btn action-btn btn-primary"
                            onClick={() => {
                              setSelectedPanne(item);
                              setShowModal(true);
                            }}
                          >
                            <i className="fas fa-edit"></i>{" "}
                          </button>
                          <button
                            className="btn btn-danger"
                            onClick={() => deletePanne(item.code)}
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default Pannes;
