import { useEffect, useState } from "react";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { findMachinesPlanningEvents } from "../../_App/Redux/Slices/plannings/planningsSlice";
import Navbar from "../../_Layouts/Navbar/Navbar";
import EventModal from "../../Components/events/EventModal";
import Calendar from "../../Components/Plannings/Calendar";
import { findMachinesApi } from "../../_App/Redux/Slices/machines/machineSlice";

const PlanningCalendar = () => {
  const dispatch = useAppDispatch();
  const [events, setEvents] = useState<any>([]);
  const [ShowModal, setShowModal] = useState(false);
  const [event, setEvent] = useState(undefined);
  const [date, setDate] = useState(undefined);

  const [rand, setRand] = useState(0);
  const increaseRand = () => {
    setRand((prevRand) => prevRand + 1);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const machinesPlaning = await dispatch(
          findMachinesPlanningEvents(null)
        );
        dispatch(findMachinesApi(null));
        const machinesEvents = machinesPlaning.payload.map((event: any) => ({
          id: event.id,
          title: event.Machine?.numero_serie + " - " + event.User?.nom,
          start: event.date,
          color: "#e2a03f",
          data: event,
        }));
        setEvents(machinesEvents);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchEvents();
  }, [dispatch, rand]);

  return (
    <>
      <Navbar titre={"Planning"} />

      <EventModal
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        event={event}
        setEvent={setEvent}
        increaseRand={increaseRand}
        date={date}
        setDate={setDate}
      />
      <Calendar
        events={events}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        setEvent={setEvent}
        setDate={setDate}
      />
    </>
  );
};

export default PlanningCalendar;
