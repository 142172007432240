import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import {
  InterventionsImages,
  ListMouvements,
} from "../../_App/Redux/Slices/interventions/interventionSlice";
import { useAppSelector } from "../../_App/Redux/hooks";
import { TicketsByCode } from "../../_App/Redux/Slices/tickets/ticketSlice";
import Ticket from "../Tickets/Ticket";
import { TicketType } from "../../_App/Types/Entites/TicketType";
import ZoomableImage from "../imagePreview/ImagePreview";

const FicheInterventionModal = ({
  ShowModal,
  setShowModal,
  intervention,
  setIntervention,
  stop,
}: any) => {
  const mouvements = useAppSelector(ListMouvements);
  const images = useAppSelector(InterventionsImages);
  const tickets = useAppSelector(TicketsByCode);
  const [showTicket, setShowTicket] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<TicketType | undefined>(
    undefined
  );

  function extractTime(timestamp: any) {
    const date = new Date(timestamp);

    // Extracting time components
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Formatting time as string
    const time = `${hours}:${minutes}:${seconds}`;

    return time;
  }
  return (
    <div>
      <Ticket
        ShowModal={showTicket}
        setShowModal={setShowTicket}
        setSelectedTicket={setSelectedTicket}
        selectedTicket={selectedTicket}
        stop={true}
      />
      <Modal
        show={ShowModal}
        onHide={() => {
          setIntervention(undefined);
          setShowModal(false);
        }}
        centered
        dialogClassName="modal-50w"
      >
        <Modal.Body>
          <div className="">
            <div className="widget widget-activity-five">
              {/* <div className="widget-heading">
                <h5 className="" onClick={() => console.log(intervention)}>
                  Fiche d'intervention{" "}
                </h5>

                <div className="task-action">
                  <button
                    type="reset"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={(e) => {
                      setIntervention(undefined);
                      setShowModal(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div> */}

              <div className="widget-content p-0">
                <div className="">
                  <div className="">
                    <div className="widget widget-account-invoice-three">
                      <div
                        className="widget-heading"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/assets/img/intervention.jpg)`,
                          backgroundPosition: "center",
                          backgroundSize: "auto",
                        }}
                      >
                        <div className="wallet-usr-info">
                          <div
                            className="usr-name"
                            style={{ display: "flex", height: "fit-content" }}
                          >
                            {intervention && images[intervention.code] ? (
                              <ZoomableImage
                                size={45}
                                imageUrl={images[intervention.code][0].path}
                              />
                            ) : null}

                            <span
                              style={{ fontSize: "20px", marginLeft: "10px" }}
                            >
                              Fiche d'intervention
                            </span>
                          </div>
                          <button
                            type="reset"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={(e) => {
                              setIntervention(undefined);
                              setShowModal(false);
                            }}
                            style={{
                              position: "absolute",
                              right: "8px",
                            }}
                          >
                            <span
                              aria-hidden="true"
                              style={{
                                height: "30px",
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                padding: "unset",
                              }}
                            >
                              &times;
                            </span>
                          </button>
                        </div>
                      </div>

                      <div
                        className="widget-amount"
                        style={{ display: "flex" }}
                      >
                        <div className="w-a-info funds-received mr-0">
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            Machine{" "}
                          </span>
                          <p>{intervention && intervention.Machine.nom}</p>
                        </div>

                        <div className="w-a-info funds-spent">
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            ODF{" "}
                          </span>
                          <p>{intervention && intervention.Odf?.libelle}</p>
                        </div>
                        <div className="w-a-info funds-spent">
                          <span
                            style={{
                              width: "100%",
                              display: "block",
                              textAlign: "center",
                            }}
                          >
                            Technicien{" "}
                          </span>
                          <p>{intervention && intervention.User?.nom}</p>
                        </div>
                      </div>

                      <div
                        className="widget-content"
                        style={{ paddingBottom: 0 }}
                      >
                        <div className="invoice-list">
                          <div className="inv-detail">
                            <div className="info-detail-1">
                              <p style={{ fontSize: "14px" }}>Date</p>
                              <p>
                                <span className="bill-amount">
                                  {" "}
                                  {intervention &&
                                    intervention.date.toString().split("T")[0] +
                                      " " +
                                      extractTime(intervention.date)}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="widget-content searchable-container list p-0">
                  {intervention && intervention.InterventionAction ? (
                    <div
                      className="searchable-items list"
                      style={{ border: "none" }}
                    >
                      <div className="items items-header-section">
                        <div className="item-content">
                          <div className="user-email col-4">
                            <h4>Objet</h4>
                          </div>
                          <div className="user-location col-4">
                            <h4>Actions</h4>
                          </div>
                          <div className="user-phone col-4">
                            <h4>Produits</h4>
                          </div>
                        </div>
                      </div>

                      <div className="items">
                        <div
                          className="item-content"
                          style={{
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="user-email col-4">
                            <p
                              className="usr-email-addr"
                              data-email="alan@mail.com"
                            >
                              {intervention &&
                                intervention.InterventionObjet?.libelle}
                            </p>
                          </div>
                          <div className="user-location col-4">
                            <p
                              className="usr-location"
                              data-location="Boston, USA"
                            >
                              {intervention &&
                                intervention.InterventionAction?.libelle}
                            </p>
                          </div>
                          <div className="user-phone col-4">
                            {mouvements &&
                              intervention &&
                              mouvements[intervention.code] &&
                              mouvements[intervention.code].map(
                                (item, index) => (
                                  <p
                                    key={index}
                                    className="usr-ph-no"
                                    style={{ width: "100%" }}
                                  >
                                    {" "}
                                    {item.Produit.libelle}{" "}
                                  </p>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="app-notes" id="cancel-row">
                    <div className="app-container">
                      <div className="app-note-container">
                        <div id="ct" className="note-container note-grid p-0">
                          {!stop && tickets &&
                          intervention &&
                          tickets[intervention.ticket_code] ? (
                            <>
                              <h5 className="mb-2">Tickets : </h5>
                              <div className="row">
                                {tickets[intervention.ticket_code].map(
                                  (item, index) => (
                                    <div
                                      className="note-item all-notes note-personal col-6"
                                      style={{
                                        maxWidth: "100%",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        setSelectedTicket(item);
                                        setShowTicket(true);
                                      }}
                                    >
                                      <div className="note-inner-content">
                                        <div className="note-content">
                                          <p
                                            className="note-title"
                                            data-noteTitle="Meeting with Kelly"
                                          >
                                            {item.type}
                                          </p>
                                          <p className="meta-time">
                                            {item.obbjet}
                                          </p>
                                          <div className="note-description-content">
                                            <p
                                              className="note-description"
                                              data-noteDescription="Curabitur facilisis vel elit sed dapibus sodales purus rhoncus."
                                            >
                                              {item.description}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          ) : null}
                          <hr></hr>
                          <div
                            className="note-item all-notes note-important"
                            style={{ maxWidth: "none", padding: 0 }}
                          >
                            <div className="note-inner-content">
                              <div
                                className="note-content"
                                style={{ minHeight: "auto" }}
                              >
                                <p className="note-title"> Commentaire</p>
                                <div className="note-description-content">
                                  <p className="note-description">
                                    {intervention && intervention.commentaire}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FicheInterventionModal;
