import React, { useEffect } from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import MachineHistorique from "../../Components/Machines/MachineHistorique";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { findHistorique } from "../../_App/Redux/Slices/historique/historiqueSlice";
import { findTodayAndFutureOdfs } from "../../_App/Redux/Slices/odfs/odfSlice";
import { useParams } from "react-router-dom";
import { findMachineProgs } from "../../_App/Redux/Slices/plannings/planningsSlice";
import {
  findTickets,
  findTicketsImage,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import {
  findIntervention,
  findinterventionImages,
  findMouvements,
  findObjets,
} from "../../_App/Redux/Slices/interventions/interventionSlice";

const HistoriquePage = () => {
  let { code } = useParams();

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (code) {
      dispatch(findHistorique(code));
      dispatch(findMachineProgs(code));
      dispatch(findTickets(null));
      dispatch(findTicketsImage(null));
      dispatch(findIntervention(null));
      dispatch(findinterventionImages(null));
      dispatch(findObjets(null));
      dispatch(findMouvements(null));
    }
  }, [dispatch]);
  return (
    <div>
      <Navbar titre={"Historique"} />
      <div className="page-container">
        <MachineHistorique />
      </div>
    </div>
  );
};

export default HistoriquePage;
