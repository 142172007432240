import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr"; // Import French locale
import { useAppDispatch } from "../../_App/Redux/hooks";
import { updatePlaningMachine } from "../../_App/Redux/Slices/plannings/planningsSlice";
import FileInput from "./FileInput";

const Calendar = ({
  events,
  ShowModal,
  setShowModal,
  setEvent,
  setDate,
}: any) => {
  const dispatch = useAppDispatch();
  const renderEventContent = (eventInfo: any) => {
    const data = eventInfo.event._def.extendedProps.data;
    return (
      <div
        onClick={() => {
          setEvent(data);
          setShowModal(true);
        }}
      >
        <b>{eventInfo.timeText}</b>
        <i>{eventInfo.event.title}</i>
      </div>
    );
  };

  useEffect(() => {
    let draggableEl = document.getElementById("external-events");
    if (draggableEl)
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEl) {
          let id = eventEl.dataset.id;
          let title = eventEl.getAttribute("title");
          let color = eventEl.dataset.color;
          let custom = eventEl.dataset.custom;

          return {
            id: id,
            title: title,
            color: color,
            custom: custom,
            create: true,
          };
        },
      });
  });

  const handleEventDrop = (info: any) => {
    let date = new Date(info.event.start); // Create a new Date object
    date.setHours(10); // Set hours to 10
    date.setMinutes(10); // Set minutes to 10
    date.setSeconds(10); // Set seconds to 10

    dispatch(
      updatePlaningMachine({
        id: info.event._def.extendedProps.data.id,
        newData: { date: date },
      })
    );
  };
  const handleDateClick = (info: any) => {
    setDate(info.dateStr);
    setShowModal(true);
  };

  return (
    <div className="App">
      <div>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          // headerToolbar={{
          //   left: "prev,next today",
          //   center: "title",
          // }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          events={events}
          droppable={true}
          locales={[frLocale]}
          locale="fr"
          eventContent={renderEventContent}
          eventDrop={handleEventDrop}
          dateClick={handleDateClick} // Add the dateClick callback
        />
        <FileInput type="Preventif" />
      </div>
    </div>
  );
};
export default Calendar;
