import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { find } from "lodash";
import { findMachineById } from "../../_App/Redux/Slices/machines/machineSlice";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import MchineForm from "./MchineForm";

const MachineFiche = () => {
  const dispatch = useAppDispatch();
  const { code } = useParams();
  const [machine, setMachine] = useState<MachineType>();
  const [ShowModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (code) {
        try {
          let res = await dispatch(findMachineById(code));
          console.log(res.payload);
          setMachine(res.payload);
        } catch (error) {
          console.error("Error fetching machine by ID:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, code, machine]);

  return (
    <div>
      <MchineForm
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        selectedMachine={machine}
        setSelectedMAchine={null}
      />
      <div className="admin-data-content layout-top-spacing">
        <div className="row layout-spacing">
          <div className="col-xl-5 col-lg-4 col-md-4 col-sm-12 layout-top-spacing">
            <div className="user-profile layout-spacing">
              <div className="widget-content widget-content-area">
                <div className="d-flex justify-content-between">
                  <h3 className="">Info</h3>
                  <a href="#" className="mt-2 edit-profile">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit-3"
                      onClick={() => setShowModal(true)}
                    >
                      <path d="M12 20h9"></path>
                      <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                    </svg>
                  </a>
                </div>
                <div className="text-center user-info">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/assets/img/no-image.jpg"
                    }
                    alt="avatar"
                    style={{width:"150px"}}
                  />
                  <p className=""> {machine?.nom} </p>
                </div>
                <div className="user-info-list">
                  <div className="">
                    <ul className="contacts-block list-unstyled">
                      <li className="contacts-block__item">
                        {"N° série : " +  machine?.numero_serie}
                      </li>
                      <li className="contacts-block__item">
                        {machine?.groupe
                          ? "Groupe : " + machine?.groupe
                          : null}
                      </li>
                      <li className="contacts-block__item">
                        {machine?.constructeur
                          ? "Constructeur : " + machine?.constructeur
                          : null}
                      </li>

                      <li className="contacts-block__item">
                        {machine?.date_instalation
                          ? "Installée le " +
                            machine?.date_instalation.toString().split("T")[0]
                          : null}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 layout-top-spacing">
            <div className="education layout-spacing ">
              <div className="widget-content widget-content-area">
                <h3 className="">Visites</h3>
                {machine?.nbr_visite ? (
                  <button type="button" className="btn btn-dark mt-3 mb-3 ml-2">
                    Nombre de visites{" "}
                    <span
                      className="badge badge-light ml-2"
                      style={{ color: "#000" }}
                    >
                      {machine?.nbr_visite}
                    </span>
                  </button>
                ) : null}
                {machine?.nbr_visite ? (
                  <button type="button" className="btn btn-dark mt-3 mb-3 ml-2">
                    Visites faites{" "}
                    <span
                      className="badge badge-light ml-2"
                      style={{ color: "#000" }}
                    >
                      {machine?.visite_faite}
                    </span>
                  </button>
                ) : null}
                {machine?.last_visite ? (
                  <div className="timeline-alter">
                    <div className="item-timeline">
                      <div className="t-meta-date">
                        <p className=""> {machine.last_visite} </p>
                      </div>
                      <div className="t-dot"></div>
                      <div className="t-text">
                        <p>Dernière visite</p>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MachineFiche;
