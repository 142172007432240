import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { OdfType } from "../../../Types/Entites/ODFType";
import _ from "lodash";

export const findOdfs = createAsyncThunk(
  "odfs/findodfs",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`odfs/findodfs`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findOdfByCode = createAsyncThunk(
  "odfs/FindOdfById",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`odfs/FindOdfById/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTodayAndFutureOdfs = createAsyncThunk(
  "odfs/findTodayAndFutureOdfs",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`odfs/findTodayAndFutureOdfs/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Odfs {
  ListOdfs: OdfType[];
  futureOdfs: { [date: string]: OdfType[] };
}

const initialState: Odfs = {
  ListOdfs: [],
  futureOdfs: {},
};

export const odfSlice = createSlice({
  name: "odfs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findOdfs.fulfilled, (state, action) => {
      return {
        ...state,
        ListOdfs: action.payload,
      };
    });
    builder.addCase(findTodayAndFutureOdfs.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "date");

      return {
        ...state,
        futureOdfs: grouped,
      };
    });
  },
});

export const ListOdfs = (state: RootState) => state.odfs.ListOdfs;
export const FutureOdfs = (state: RootState) => state.odfs.futureOdfs;

export default odfSlice.reducer;
