import React, { useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { MachineDataType } from "../../_App/Types/Forms/MachineDataType";
import {
  saveMachineApi,
  updateMachineById,
} from "../../_App/Redux/Slices/machines/machineSlice";
import { Modal } from "react-bootstrap";
import { ListOdfs } from "../../_App/Redux/Slices/odfs/odfSlice";

const MchineForm = ({
  ShowModal,
  setShowModal,
  selectedMachine,
  setSelectedMAchine,
  load,
  setLoad,
}: any) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<MachineDataType>();
  const odfs = useAppSelector(ListOdfs);
  var defaultValues = {
    code: selectedMachine && selectedMachine.code ? selectedMachine.code : "",
    nom: selectedMachine && selectedMachine.nom ? selectedMachine.nom : "",
    numero_serie:
      selectedMachine && selectedMachine.numero_serie
        ? selectedMachine.numero_serie
        : null,

    image:
      selectedMachine && selectedMachine.image ? selectedMachine.image : null,
    groupe:
      selectedMachine && selectedMachine.groupe ? selectedMachine.groupe : null,
    status:
      selectedMachine && selectedMachine.status
        ? selectedMachine.status
        : "En arrêt",
  };
  const closeModal = () => {
    if (setSelectedMAchine !== null) setSelectedMAchine(undefined);
    setShowModal(false);
  };
  useEffect(() => {
    if (selectedMachine) {
      reset({
        code: selectedMachine.code,
        numero_serie: selectedMachine.numero_serie,
        image: selectedMachine.image,
        groupe: selectedMachine.groupe,
        nom: selectedMachine.nom,
        status: selectedMachine.status,
      });
    } else {
      reset({
        code: "",
        numero_serie: "",
        image: "",
        groupe: "",
        nom: "",
        status: "",
      });
    }
  }, [selectedMachine, ShowModal]);

  const onSubmit: SubmitHandler<MachineDataType> = async (data) => {
    if (selectedMachine) {
      try {
        await dispatch(
          updateMachineById({ id: selectedMachine.code, data: data })
        );
        console.log("Machine updated successfully!");
        setLoad(!load);
        closeModal();
      } catch (error) {
        console.error("Error updating machine:", error);
      }
    } else {
      data.status = "En arrêt";
      data.date_instalation = new Date();
      let res = await dispatch(saveMachineApi(data));
      if (res.payload.success) {
        setLoad(!load);
        closeModal();
      }
    }
  };

  return (
    <div>
      <Modal show={ShowModal} onHide={() => closeModal()}>
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              {selectedMachine
                ? "Modifier la machine " + selectedMachine.nom
                : "Nouvelle machine"}
            </span>
          </Modal.Title>
          <button
            type="reset"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              if (setSelectedMAchine !== null) setSelectedMAchine(undefined);
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form id="addContactModalTitle" onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="contact-name">
                        <i className="flaticon-user-11"></i>
                        <input
                          type="text"
                          id="c-name"
                          className="form-control"
                          placeholder="Code"
                          defaultValue={defaultValues.code}
                          {...register("code", {
                            required: "name is required",
                          })}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-email">
                        <i className="flaticon-mail-26"></i>
                        <input
                          type="text"
                          id="c-email"
                          className="form-control"
                          placeholder="N° serie"
                          defaultValue={defaultValues.numero_serie}
                          {...register("numero_serie")}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <div className="contact-name">
                        <i className="flaticon-user-11"></i>
                        <input
                          type="text"
                          id="c-name"
                          className="form-control"
                          placeholder="Nom"
                          defaultValue={defaultValues.nom}
                          {...register("nom", {
                            required: "name is required",
                          })}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-phone">
                        <i className="flaticon-telephone"></i>
                        <select
                          className="form-control"
                          {...register("groupe")}
                        >
                          <option
                            value={""}
                            defaultValue={defaultValues.groupe}
                          >
                            {" "}
                            Type
                          </option>
                          <option
                            value={"Automatique"}
                            defaultValue={defaultValues.groupe}
                          >
                            {" "}
                            Automatique
                          </option>
                          <option
                            value={"Semi-automatique"}
                            defaultValue={defaultValues.groupe}
                          >
                            {" "}
                            Semi-automatique
                          </option>
                          <option
                            value={"Manuelle"}
                            defaultValue={defaultValues.groupe}
                          >
                            {" "}
                            Manuelle
                          </option>
                          <option
                            value={"Autres"}
                            defaultValue={defaultValues.groupe}
                          >
                            {" "}
                            Autres
                          </option>
                        </select>
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="searchable-container modal-footer">
              <button
                className="btn btn-warning"
                data-dismiss="modal"
                type="reset"
                onClick={() => closeModal()}
              >
                {" "}
                <i className="flaticon-delete-1"></i> Annuler
              </button>

              <button type="submit" id="btn-add" className="btn btn-primary">
                Enregistrer
              </button>
            </div>
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};

export default MchineForm;
