import React, { useEffect } from "react";
import Machines from "../../Components/Machines/Machines";
import Navbar from "../../_Layouts/Navbar/Navbar";

const MachinesPage = () => {
  return (
    <div>
      <Navbar titre={"Machines"} />
      <div className="page-container">
        <Machines />{" "}
      </div>
    </div>
  );
};

export default MachinesPage;
