import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { findProdPlanningEvents } from "../../_App/Redux/Slices/plannings/planningsSlice";
import ProdPrograms from "../../Components/Plannings/ProdPrograms";
import Navbar from "../../_Layouts/Navbar/Navbar";

const ProdProgramsPage = () => {
  const dispatch = useAppDispatch();
  const [reload, setReload] = useState(true);

  useEffect(() => {
    dispatch(findProdPlanningEvents(null));
  }, [dispatch, reload]);

  return (
    <div>
      <Navbar titre={"Programme de production"} />
      <div className="page-container">
        {" "}
        <ProdPrograms reload={reload} setReload={setReload} />
      </div>
    </div>
  );
};

export default ProdProgramsPage;
