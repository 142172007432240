import React, { useEffect } from "react";

import Navbar from "../../_Layouts/Navbar/Navbar";
import { useAppDispatch } from "../../_App/Redux/hooks";
import Dashboard from "../../Components/Dashboard/Dashboard";
import { findProgsProd } from "../../_App/Redux/Slices/plannings/planningsSlice";
import { findStatusOdf } from "../../_App/Redux/Slices/checklists/checklistSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
const DashboardPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(findProgsProd(null));
    dispatch(findStatusOdf(null));
  }, [dispatch]);

  return (
    <div className="admin-data-content layout-top-spacing">
      <Navbar titre={"Dashboard"} />
      <Dashboard />
    </div>
  );
};
export default DashboardPage;
