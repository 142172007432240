import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./_Layouts/Layout/Layout";
import UsersPage from "./Pages/Users/UsersPage";
import axios from "axios";
import "./App.css";

import { useEffect } from "react";
import MachinesPage from "./Pages/Machines/MachinesPage";
import MachinesFichePage from "./Pages/Machines/MachinesFichePage";
import MachinesListPage from "./Pages/Machines/MachinesListPage";
import { useAppDispatch, useAppSelector } from "./_App/Redux/hooks";
import ChecklistPage from "./Pages/Checklist/ChecklistCuratifPage";
import PannesPage from "./Pages/Pannes/PannesPage";
import DashboardPage from "./Pages/Dashboard/DashboardPage";
import LoginPage from "./Pages/Auth/LoginPage";
import { Auth } from "./_App/Redux/Slices/auth/authSlice";
import HistoriqueNotifications from "./Pages/Notifications/HistoriqueNotifications";
import InterventionsPage from "./Pages/Interventions/InterventionsPage";
import HistoriquePage from "./Pages/Historique/HistoriquePage";
import PlanningCalendar from "./Pages/Plannings/PlanningCalendar";
import ListChecklistsPreventif from "./Components/Checklist/ListChecklistsPreventif";
import ListChecklistsCuratif from "./Components/Checklist/ListChecklistsCuratif";
import ProdProgramsPage from "./Pages/Plannings/ProdProgramsPage";
import ImagePreview from "./Components/imagePreview/ImagePreview";
import ProductsPage from "./Pages/Produits/ProductsPage";
import { findTicketsImage } from "./_App/Redux/Slices/tickets/ticketSlice";

function App() {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  const dispatch = useAppDispatch();
  const userOnline = useAppSelector(Auth);
  useEffect(() => {
    if (
      userOnline &&
      userOnline.token &&
      window.location.pathname === "/login"
    ) {
      window.location.href = "/";
    } else if (!userOnline && window.location.pathname !== "/login") {
      window.location.href = "/login";
    }
  }, [dispatch, userOnline]);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          {userOnline && userOnline.token && userOnline.user ? (
            <Route path="/" element={<Layout />}>
              <Route path="/interventions" element={<InterventionsPage />} />
              <Route
                path="/machineHistorique/:code"
                element={<HistoriquePage />}
              />

              <Route path="/machinesList" element={<MachinesListPage />} />

              <Route
                path="/notifications"
                element={<HistoriqueNotifications />}
              />
              <Route path="/calendar" element={<PlanningCalendar />} />

              <Route
                path="/curatifChecklists/:odfCode/:machineCode"
                element={<ListChecklistsCuratif />}
              />
              <Route
                path="/preventifChecklists/:planing/:machineCode"
                element={<ListChecklistsPreventif />}
              />

              <Route
                path="/machines/fiche/:code"
                element={<MachinesFichePage />}
              />

              <Route path="/" element={<DashboardPage />} />

              <Route
                path="/odfs/machines/:odfcode"
                element={<MachinesPage />}
              />
              <Route path="/prodprograms" element={<ProdProgramsPage />} />

              {/* settings */}
              {userOnline.user.isadmin === 1 ? (
                <>
                  {" "}
                  <Route path="/machines" element={<MachinesPage />} />
                  <Route path="/users" element={<UsersPage />} />
                  <Route path="/checklist" element={<ChecklistPage />} />
                  <Route path="/pannes" element={<PannesPage />} />
                  <Route path="/produits" element={<ProductsPage />} />{" "}
                </>
              ) : null}
            </Route>
          ) : (
            <Route path="/login" element={<LoginPage />} />
          )}
          <Route path="/stats" element={<ImagePreview />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
