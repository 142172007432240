import React, { useState, useEffect } from "react";

interface EditableCellProps {
  value: any;
  onSave: (newValue: any) => void; // updated to accept any type
  type: string | null;
}

const EditableCell: React.FC<EditableCellProps> = ({ value, onSave, type }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleBlur = () => {
    setIsEditing(false);
    if (currentValue !== value) {
      onSave(currentValue);
    }
  };

  const handleSwitchChange = () => {
    const newValue = currentValue === 1 ? 0 : 1;
    setCurrentValue(newValue);
    onSave(newValue); // Save the new value immediately on change
  };

  return (
    <td
      onClick={() => {
        setIsEditing(true);
      }}
    >
      {isEditing ? (
        type && type === "date" ? (
          <input
            id="start-date"
            placeholder="Start Date"
            className="form-control"
            type="date"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={handleBlur}
            autoFocus
          />
        ) : type && type === "switch" ? (
          <label className="switch s-icons s-outline s-outline-danger">
            <input
              type="checkbox"
              checked={currentValue === 1}
              onChange={handleSwitchChange}
              onBlur={handleBlur}
            />
            <span className="slider round"></span>
          </label>
        ) : (
          <input
            className="form-control"
            type="text"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={handleBlur}
            autoFocus
          />
        )
      ) : type && type === "date" ? (
        value.toString().split("T")[0]
      ) : type && type === "switch" ? (
        <label className="switch s-icons s-outline s-outline-danger">
          <input
            type="checkbox"
            checked={currentValue === 1}
            onChange={handleSwitchChange}
            onBlur={handleBlur}
          />
          <span className="slider round"></span>
        </label>
      ) : (
        value
      )}
    </td>
  );
};

export default EditableCell;
