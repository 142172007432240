import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { UserType } from "../../../Types/Entites/User";
import { UserRoleType } from "../../../Types/Entites/UserRoleType";
import { UserDataType } from "../../../Types/Forms/UserDataType";
import _ from "lodash";

export const saveUserAPi = createAsyncThunk(
  "users/createUserApi",
  async (data: UserDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("users/createUserApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findUsersApi = createAsyncThunk(
  "users/findUsers",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`users/findUsers`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findRoles = createAsyncThunk(
  "users/findRoles",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`users/findRoles`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ code, data }: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(`users/updateUser/${code}`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface Users {
  usersList: UserType[];
  usersListByRole: { [role: string]: UserType[] };

  userRoles: UserRoleType[];
}

const initialState: Users = {
  usersList: [],
  usersListByRole: {},
  userRoles: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveUserAPi.fulfilled, (state, action) => {
      state.usersList.splice(0, 0, action.payload.data);
    });
    builder.addCase(findUsersApi.fulfilled, (state, action) => {
      const grouped = _.groupBy(action.payload, "role_code");

      return {
        ...state,
        usersList: action.payload,
        usersListByRole: grouped,
      };
    });
    builder.addCase(findRoles.fulfilled, (state, action) => {
      return {
        ...state,
        userRoles: action.payload,
      };
    });
  },
});

export const ListUsers = (state: RootState) => state.users.usersList;
export const ListUsersPerRole = (state: RootState) =>
  state.users.usersListByRole;

export const ListRoles = (state: RootState) => state.users.userRoles;

export default userSlice.reducer;
