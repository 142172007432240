import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import {
  ListMachines,
  findMachinesApi,
} from "../../_App/Redux/Slices/machines/machineSlice";

import { useParams } from "react-router-dom";
import Loader from "../../_Layouts/Loader/Loader";
import { ChangeMachineStatus } from "./ChangeMachineStatusModal";
import { MachineType } from "../../_App/Types/Entites/MachineType";

const LandingMachines = () => {
  let { odfcode } = useParams();
  const dispatch = useAppDispatch();
  const machines = useAppSelector(ListMachines);

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [machine, setMachine] = useState<undefined | MachineType>(undefined);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading to true before making API calls

        // Dispatch API calls
        await dispatch(findMachinesApi(null));

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, odfcode]);
  return (
    <div
      className="searchable-container m-0"
      style={{ maxWidth: "fit-content" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div
          className="layout-top-spacing"
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          <ChangeMachineStatus
            ShowModal={showModal}
            setShowModal={setShowModal}
            selectedMachine={machine}
            setSelectedMachine={setMachine}
          
          />
          {machines &&
            machines.map((item, index) =>
              item && item.checklist === 1 ? (
                <div key={index} style={{ cursor: "pointer" }}>
                  <div
                    className="soft-icons"
                    style={{
                      backgroundColor:
                        item.status && item.status === "En arrêt"
                          ? "#555555"
                          : item.status && item.status === "En panne"
                          ? "#e7515a"
                          : item.status && item.status === "En réparation"
                          ? "#e2a03f"
                          : item.status && item.status === "En attente"
                          ? "#5c80ef"
                          : "#1abc9c",
                    }}
                    onClick={() =>
                      (window.location.href = "/machineHistorique/" + item.code)
                    }
                  >
                    <p>{item.numero_serie}</p>
                  </div>
                  <div
                    className="machine-etiquette"
                    onClick={() => {
                      setMachine(item);
                      setShowModal(true);
                    }}
                  >
                    {item.status}
                  </div>
                </div>
              ) : null
            )}
        </div>
      )}
    </div>
  );
};

export default LandingMachines;
