import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { HistoriqueType } from "../../../Types/Entites/HistoriqueType";
import _ from "lodash";

export const findHistorique = createAsyncThunk(
  "historique/findHistorique",
  async (code: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`historique/findHistorique/${code}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const createHistoryItem = createAsyncThunk(
  "historique/createHistoryItem",
  async (data: HistoriqueType, { rejectWithValue }) => {
    try {
      const response = await axios.post("historique/createHistoryItem", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface historique {
  historique: { [odf: string]: HistoriqueType[] };
}

const initialState: historique = {
  historique: {},
};

export const historiqueSlice = createSlice({
  name: "historique",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findHistorique.fulfilled, (state, action) => {
      const groupedByMachine = _.groupBy(action.payload, "odf_code");

      // console.log(
      //   "before sort",
      //   Object.entries(groupedByMachine).map(([key, items]: any) => ({
      //     [key]: items.map((item: any) => item.date),
      //   }))
      // );

      // Sorting each group by date and time
      const sortedGroupedByMachine = _.mapValues(
        groupedByMachine,
        (group: any) =>
          _.orderBy(
            group,
            [(item: any) => `${item.date}T${item.heure}`], // Combine date and heure fields
            ["desc"]
          )
      );

      // Log dates and times after sorting
      // console.log(
      //   "after sort",
      //   Object.entries(sortedGroupedByMachine).map(([key, items]: any) => ({
      //     [key]: items.map((item: any) => `${item.date}T${item.heure}`),
      //   }))
      // );
      return {
        ...state,
        historique: sortedGroupedByMachine,
      };
    });
  },
});

export const Historique = (state: RootState) => state.historique.historique;

export default historiqueSlice.reducer;
