import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import { ProduitType } from "../../../Types/Entites/ProduitType";

export const saveProduitApi = createAsyncThunk(
  "produits/createProduit",
  async (data: ProduitType, { rejectWithValue }) => {
    try {
      const response = await axios.post("produits/createProduit", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findProduitsApi = createAsyncThunk(
  "produits/findProduits",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`produits/findProduits`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findProduitById = createAsyncThunk(
  "produits/findProduitByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`produits/findProduitByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateProduitById = createAsyncThunk(
  "produits/updateProduit",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`produits/updateProduit/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteProduitApi = createAsyncThunk(
  "produits/deleteProduit",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`produits/deleteProduit/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Produits {
  produitsList: ProduitType[];
  produitListByCode: { [code: string]: ProduitType[] };
}

const initialState: Produits = {
  produitsList: [],
  produitListByCode: {},
};

export const produitSlice = createSlice({
  name: "produits",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveProduitApi.fulfilled, (state, action) => {
      let newProduit = action.payload.data;
      newProduit.allChecklistsPassed = null;
      state.produitsList.splice(0, 0, newProduit);
    });
    builder.addCase(updateProduitById.fulfilled, (state, action) => {
      const index = _.findIndex(state.produitsList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedListMAchines = [...state.produitsList];
        updatedListMAchines[index] = {
          ...updatedListMAchines[index],
        };
        return {
          ...state,
          produitsList: updatedListMAchines,
        };
      }

      // Return original state if index is not found
      return state;
    });
    builder.addCase(findProduitsApi.fulfilled, (state, action) => {
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        produitsList: action.payload,
        produitListByCode: groupedByCode,
      };
    });
  },
});

export const ListProduits = (state: RootState) => state.produits.produitsList;
export const ListProduitsByCode = (state: RootState) =>
  state.produits.produitListByCode;
export default produitSlice.reducer;
