import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import NotificationAffectation from "../../Components/Tickets/NotificationAffectation";
import {
  findTickets,
  ImagesTickets,
  TicketsList,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import { TicketType } from "../../_App/Types/Entites/TicketType";
import { findUsersApi } from "../../_App/Redux/Slices/users/userSlice";

const NotificationBar = () => {
  const dispatch = useAppDispatch();
  const tickets = useAppSelector(TicketsList);

  const [showModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);

  const [showSeenNotifications, setShowSeenNotification] = useState(false);

  const [selectedNotification, setSelectedNotification] =
    useState<TicketType>();

  useEffect(() => {
    dispatch(findUsersApi(null));
    dispatch(findTickets(null));
  }, [dispatch, load]);

  return (
    <div className="col-right" style={{ zIndex: 1 }}>
      <NotificationAffectation
        ShowModal={showModal}
        setShowModal={setShowModal}
        notification={selectedNotification}
        load={load}
        setLoad={setLoad}
      />
      <div
        className="col-right-content m-0"
        style={{ padding: "32px 20px 10px 20px" }}
      >
        <div className="col-right-content-container">
          <div className="activity-section">
            <div className="widget-calendar">
              <div className="widget-title">
                <h5 onClick={() => console.log(tickets)}>Notifications</h5>
                <div style={{ display: "flex" }}>
                  <h6 style={{ margin: "5px" }}>Lus </h6>
                  <input
                    type="checkbox"
                    className=""
                    checked={showSeenNotifications}
                    onChange={() =>
                      setShowSeenNotification(!showSeenNotifications)
                    }
                  />
                </div>
              </div>

              <div
                className="widget-calendar-lists"
                style={{ height: "100vh" }}
              >
                <div
                  className="widget-calendar-lists-scroll"
                  style={{ overflowY: "auto", maxHeight: "100%" }}
                >
                  {tickets &&
                    tickets.map((item, index) =>
                      !showSeenNotifications ? (
                        item.cloture === 0 && item.affected_to === null ? (
                          <div
                            key={index}
                            onClick={() => {
                              setSelectedNotification(item);
                              setShowModal(true);
                            }}
                          >
                            <a href="#" className="w-calendar w-calendar-c6">
                              <div className="w-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-alert-triangle"
                                >
                                  <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                  <line x1="12" y1="9" x2="12" y2="13"></line>
                                  <line
                                    x1="12"
                                    y1="17"
                                    x2="12.01"
                                    y2="17"
                                  ></line>
                                </svg>
                              </div>
                              <p
                                className="w-calendar-text"
                                style={{ width: "100%" }}
                              >
                                <span className="calendar-number">
                                  {" "}
                                  {item.CreatorUser?.nom}
                                  {" : "}
                                </span>{" "}
                                {item.type} <br />
                                <span
                                  className="calendar-name"
                                  style={{ float: "right" }}
                                >
                                  {" "}
                                  {item.date.toString().split("T")[0]}{" "}
                                </span>
                              </p>
                            </a>
                          </div>
                        ) : null
                      ) : (
                        <div
                          key={index}
                          onClick={() => {
                            setSelectedNotification(item);
                            setShowModal(true);
                          }}
                        >
                          <a href="#" className="w-calendar w-calendar-c6">
                            <div className="w-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-alert-triangle"
                              >
                                <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                                <line x1="12" y1="9" x2="12" y2="13"></line>
                                <line x1="12" y1="17" x2="12.01" y2="17"></line>
                              </svg>
                            </div>
                            <p
                              className="w-calendar-text"
                              style={{ width: "100%" }}
                            >
                              <span className="calendar-number">
                                {" "}
                                {item.CreatorUser?.nom}
                                {" : "}
                              </span>{" "}
                              {item.type} <br />
                              <span
                                className="calendar-name"
                                style={{ float: "right" }}
                              >
                                {" "}
                                {item.date.toString().split("T")[0]}{" "}
                              </span>
                            </p>
                          </a>
                        </div>
                      )
                    )}
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationBar;
