import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { UserType } from "../../../Types/Entites/User";
import { UserRoleType } from "../../../Types/Entites/UserRoleType";
import { UserDataType } from "../../../Types/Forms/UserDataType";
import { PanneType } from "../../../Types/Entites/PanneType";
import _ from "lodash";

export const savePanneApi = createAsyncThunk(
  "pannes/createPanne",
  async (data: PanneType, { rejectWithValue }) => {
    try {
      const response = await axios.post("pannes/createPanne", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findPannes = createAsyncThunk(
  "pannes/findPannes",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`pannes/findPannes`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updatePanneByCode = createAsyncThunk(
  "pannes/updatePanne",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`pannes/updatePanne/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deletePanneApi = createAsyncThunk(
  "pannes/deletePanne",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`pannes/deletePanne/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Pannes {
  pannesList: PanneType[];
  pannesByType: { [type: string]: PanneType[] };
}

const initialState: Pannes = {
  pannesList: [],
  pannesByType: {},
};

export const panneSlice = createSlice({
  name: "pannes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePanneApi.fulfilled, (state, action) => {
      // if (action.payload.data) {
      //   const data = action.payload.data;
      //   const type = data.type;

      //   return {
      //     ...state,
      //     pannesByType: {
      //       ...state.pannesByType,
      //       [type]: [
      //         ...(state.pannesByType[type] || []),
      //         ...action.payload.data,
      //       ],
      //     },
      //   };
      // }
    });
    builder.addCase(findPannes.fulfilled, (state, action) => {
      const groupedbytype = _.groupBy(action.payload, "type");
      return {
        ...state,
        pannesList: action.payload,
        pannesByType: groupedbytype,
      };
    });
  },
});

export const PannesList = (state: RootState) => state.pannes.pannesList;
export const panneByType = (state: RootState) => state.pannes.pannesByType;

export default panneSlice.reducer;
