import React from "react";
import { useAppDispatch } from "../../_App/Redux/hooks";

const Navbar = ({ titre }: any) => {
  const dispatch = useAppDispatch();

  return (
    <div className="header-container">
      <header className="header navbar navbar-expand-sm row">
        <div className="d-flex">
          <a href="#" className="sidebarCollapse" data-placement="bottom">
            <div className="bt-menu-trigger">
              <span></span>
            </div>
          </a>
          <div className="page-header">
            <div className="page-title">
              <h3>{titre}</h3>
            </div>
          </div>
        </div>

        {/* <div className="header-actions">
          <div className="toggle-notification-bar">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-bell"
            >
              <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
              <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
            </svg>
          </div>
        </div> */}
      </header>
    </div>
  );
};

export default Navbar;
