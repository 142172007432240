import React, { useEffect } from "react";
import Machines from "../../Components/Machines/Machines";
import Navbar from "../../_Layouts/Navbar/Navbar";
import Checklist from "../../Components/Checklist/Checklist";
import { useAppDispatch } from "../../_App/Redux/hooks";
import {
  findAllChecklists,
  findChecklistsGroups,
} from "../../_App/Redux/Slices/checklists/checklistSlice";

const ChecklistPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(findAllChecklists(null));
    dispatch(findChecklistsGroups(null));
  }, [dispatch]);
  return (
    <div>
      <Navbar titre={"Checklists"} />
      <div className="page-container">
        <Checklist />{" "}
      </div>
    </div>
  );
};

export default ChecklistPage;
