import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ListUsers } from "../../_App/Redux/Slices/users/userSlice";
import {
  affectUser,
  sendAffectationTicket,
  updateTicket,
} from "../../_App/Redux/Slices/tickets/ticketSlice";
import { createHistoryItem } from "../../_App/Redux/Slices/historique/historiqueSlice";
import { currentUser } from "../../_App/Redux/Slices/auth/authSlice";
import { updateResult } from "../../_App/Redux/Slices/checklists/checklistSlice";

const NotificationAffectation = ({
  ShowModal,
  setShowModal,
  notification,
  load,
  setLoad,
}: any) => {
  const users = useAppSelector(ListUsers);
  const dispatch = useAppDispatch();
  const [user, setUser] = useState("");
  const userOnline = useAppSelector(currentUser);
  const [accept, setAccept] = useState<boolean | null>(null);
  var currentDate = new Date();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();
  var formattedTime = hours + ":" + minutes + ":" + seconds;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (notification && notification.type === "dérogation" && accept !== null) {
      // const sendNotif = await dispatch(
      //   sendAffectationTicket({
      //     role: notification.CreatorUser.role_code,
      //     text: accept ? "Acceptation de dérogation" : "Refus de dérogation",
      //   })
      // );
      const updateResultResponse = await dispatch(
        updateResult({
          id: notification.result_id,
          newData: {
            derogation: accept ? 1 : 0,
            derogateur: userOnline?.code,
            heure_derogation: formattedTime,
          },
        })
      );

      const updateTicketResponse = await dispatch(
        updateTicket({
          id: notification.id,
          newData: {
            cloture: 1,
          },
        })
      );

      const saveHsitory = await dispatch(
        createHistoryItem({
          code: notification.code,
          machine_code: notification.machine_code,
          odf_code: notification.odf_code,
          date: new Date(),
          heure: formattedTime,
          operation: accept
            ? "Acceptation de dérogation"
            : "Refus de dérogation",
          user_code: userOnline?.code?.toString(),
          actif: "1",
        })
      );
      setLoad(!load);
      setShowModal(false);
    }
    if (user) {
      const res = await dispatch(
        affectUser({
          id: notification.id,
          data: {
            affected_to: JSON.parse(user).code,
            date_affectation: new Date(),
          },
        })
      );
      // const sendNotif = await dispatch(
      //   sendAffectationTicket({
      //     role: JSON.parse(user).role_code,
      //     text: "Veuillez aller réparer la machine",
      //   })
      // );
      const saveHsitory = await dispatch(
        createHistoryItem({
          code: notification.code,
          machine_code: notification.machine_code,
          odf_code: notification.odf_code,
          date: new Date(),
          heure: formattedTime,
          operation: "Affectation",
          user_code: userOnline?.code?.toString(),
          actif: "1",
        })
      );
      setLoad(!load);
      setShowModal(false);
    } else {
      console.log("user required");
    }
  };
  return (
    <div>
      <Modal show={ShowModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <span
              className="modal-title"
              id="addCategorieLabel"
              style={{ textTransform: "capitalize" }}
            >
              {" "}
              {notification?.CreatorUser?.nom + " : " + notification?.type}
            </span>
          </Modal.Title>
          <button className="close" onClick={() => setShowModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form id="addContactModalTitle" onSubmit={onSubmit}>
          <Modal.Body>
            <h6 style={{ textTransform: "capitalize" }}>
              {notification?.objet}{" "}
            </h6>
            <p> {"ODF : " + notification?.odf_code} </p>
            <p> {"Machine : " + notification?.machine_code} </p>
            <p>
              {" "}
              {notification?.Panne
                ? "Panne : " + notification?.Panne?.libelle
                : null}{" "}
            </p>
            <p> {notification?.description} </p>

            {notification && notification.type === "dérogation" ? (
              <>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="row mb-2">
                        <div className="col-md-12">
                          <div className="contact-name">
                            <i className="flaticon-user-11"></i>
                            {/* <select
                              className="form-control"
                              onChange={(e) => setUser(e.currentTarget.value)}
                            >
                              <option value={""}>
                                {" "}
                                Affecter un utilisateur{" "}
                              </option>
                              {users &&
                                users.map((item, index) => (
                                  <option
                                    key={index}
                                    value={JSON.stringify(item)}
                                  >
                                    {" "}
                                    {item.nom}{" "}
                                  </option>
                                ))}
                            </select> */}
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="searchable-container modal-footer">
                  <button
                    className="btn btn-warning"
                    data-dismiss="modal"
                    type="submit"
                    onClick={() => setAccept(false)}
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Refuser
                  </button>

                  <button
                    type="submit"
                    id="btn-add"
                    className="btn btn-primary"
                    onClick={() => setAccept(true)}
                  >
                    Valider
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="modal-body">
                  <i
                    className="flaticon-cancel-12 close"
                    data-dismiss="modal"
                  ></i>
                  <div className="add-contact-box">
                    <div className="add-contact-content">
                      <div className="row mb-2">
                        <div className="col-md-12">
                          <div className="contact-name">
                            <i className="flaticon-user-11"></i>
                            <select
                              className="form-control"
                              onChange={(e) => setUser(e.currentTarget.value)}
                            >
                              <option value={""}>
                                {" "}
                                Affecter un utilisateur{" "}
                              </option>
                              {users &&
                                users.map((item, index) => (
                                  <option
                                    key={index}
                                    value={JSON.stringify(item)}
                                  >
                                    {" "}
                                    {item.nom}{" "}
                                  </option>
                                ))}
                            </select>
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="searchable-container modal-footer">
                  <button
                    className="btn btn-warning"
                    data-dismiss="modal"
                    type="reset"
                    onClick={() => setShowModal(false)}
                  >
                    {" "}
                    <i className="flaticon-delete-1"></i> Annuler
                  </button>

                  <button
                    type="submit"
                    id="btn-add"
                    className="btn btn-primary"
                  >
                    Enregistrer
                  </button>
                </div>
              </>
            )}
          </Modal.Body>
        </form>
      </Modal>
    </div>
  );
};

export default NotificationAffectation;
