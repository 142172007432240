import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListUsers,
  findRoles,
  findUsersApi,
  updateUser,
} from "../../_App/Redux/Slices/users/userSlice";
import UserForm from "./UserForm";
import { UserType } from "../../_App/Types/Entites/User";
import Swal from "sweetalert2";

const Users = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(ListUsers);
  const [selectedUser, setSelectedUser] = useState<UserType | undefined>(
    undefined
  );
  const [ShowModal, setShowModal] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    dispatch(findUsersApi(null));
    dispatch(findRoles(null));
  }, [dispatch, selectedUser, load]);

  const blockUser = (code: any) => {
    Swal.fire({
      title: "Êtes-vous sûr(e) ?",
      html: "Cette action ne supprimera pas définitivement l'utilisateur, mais le bloquera temporairement. <br><br><strong>Voulez-vous continuer ?</strong>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui, bloquez-le !",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateUser({ code: code, data: { isactif: 0 } }));
        setLoad(!load);
        Swal.fire({
          title: "Terminé !",
          text: "Votre utilisateur a été bloqué.",
          icon: "success",
        });
      }
    });
  };
  return (
    <div>
      <UserForm
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        load={load}
        setLoad={setLoad}
      />
      <div className="widget-content searchable-container list">
        <div className="row">
          <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10 filtered-list-search layout-spacing align-self-center mb-0">
            <form className="form-inline my-2 my-lg-0">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-search"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input
                  type="text"
                  className="form-control product-search"
                  id="input-search"
                  placeholder="Search"
                />
              </div>
            </form>
          </div>

          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2 text-sm-right text-center layout-spacing align-self-center">
            <div className="d-flex justify-content-sm-end justify-content-center">
              <svg
                id="btn-add-contact"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-user-plus"
                onClick={() => {
                  setSelectedUser(undefined);
                  setShowModal(true);
                }}
              >
                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="8.5" cy="7" r="4"></circle>
                <line x1="20" y1="8" x2="20" y2="14"></line>
                <line x1="23" y1="11" x2="17" y2="11"></line>
              </svg>
            </div>
          </div>
        </div>

        <div className="searchable-items list">
          <div className="items items-header-section">
            <div className="item-content">
              <div className="col-3">
                <h4>Nom</h4>
              </div>
              <div className="user-email col-2">
                <h4>Mail</h4>
              </div>
              <div className="user-location col-2">
                <h4>Role</h4>
              </div>
              <div className="user-phone col-2">
                <h4>Mobile</h4>
              </div>
              <div className="action-btn col-1">
                <h4>Actions</h4>
              </div>
            </div>
          </div>
          {users &&
            users.map((item, index) => (
              <div className="items" key={item.id}>
                <div
                  className="item-content"
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="user-profile col-3">
                    <img
                      src={
                        item.photo
                          ? item.photo
                          : "assets/assets/img/no-image.jpg"
                      }
                      alt="avatar"
                    />
                    <div className="user-meta-info">
                      <p className="user-name" data-name="Alan Green">
                        {item.nom + " " + item.prenom}
                      </p>
                      <p className="user-work" data-occupation="Web Developer">
                        {item.grade}
                      </p>
                    </div>
                  </div>
                  <div className="user-email col-2">
                    <p className="usr-email-addr" data-email="alan@mail.com">
                      {item.email}
                    </p>
                  </div>
                  <div className="user-location col-2">
                    <p className="usr-location" data-location="Boston, USA">
                      {item.role_code}
                    </p>
                  </div>
                  <div className="user-phone col-2">
                    <p className="usr-ph-no" data-phone="+1 (070) 123-4567">
                      {item.mobile}
                    </p>
                  </div>
                  <div className="action-btn col-1 p-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-edit-2 edit"
                      onClick={() => {
                        setSelectedUser(item);
                        setShowModal(true);
                      }}
                    >
                      <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-user-x delete"
                      onClick={() => blockUser(item.code)}
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="8.5" cy="7" r="4"></circle>
                      <line x1="18" y1="8" x2="23" y2="13"></line>
                      <line x1="23" y1="8" x2="18" y2="13"></line>
                    </svg>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Users;
