// Inside UsersPage.jsx
import React from "react";
import Navbar from "../../_Layouts/Navbar/Navbar";
import Interventions from "../../Components/Interventions/Interventions";

const InterventionsPage = () => {
  return (
    <div>
      <Navbar titre={"Interventions"} />
      <div className="page-container">
        <Interventions />
      </div>
    </div>
  );
};

export default InterventionsPage;
