import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { MachineType } from "../../../Types/Entites/MachineType";
import { MachineDataType } from "../../../Types/Forms/MachineDataType";
import _ from "lodash";

export const saveMachineApi = createAsyncThunk(
  "machines/createMachine",
  async (data: MachineDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("machines/createMachine", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findMachinesApi = createAsyncThunk(
  "machines/findMachines",
  async (id: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/findMachines`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const findMachineById = createAsyncThunk(
  "machines/findMachineByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`machines/findMachineByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateMachineById = createAsyncThunk(
  "machines/updateMachine",
  async ({ id, data }: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`machines/updateMachine/${id}`, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const deleteMachineApi = createAsyncThunk(
  "machines/deleteMachine",
  async (code: any, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`machines/deleteMachine/${code}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface Machines {
  machinesList: MachineType[];
  machineListByCode: { [code: string]: MachineType[] };

  ListMachinesPerOdfs: {
    [Id: string]: MachineType[];
  };
}

const initialState: Machines = {
  machinesList: [],
  machineListByCode: {},
  ListMachinesPerOdfs: {},
};

export const machineSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveMachineApi.fulfilled, (state, action) => {
      let newMachine = action.payload.data;
      newMachine.allChecklistsPassed = null;
      state.machinesList.splice(0, 0, newMachine);
    });
    builder.addCase(updateMachineById.fulfilled, (state, action) => {
      const index = _.findIndex(state.machinesList, {
        id: action.payload.data.id,
      });
      if (index !== -1) {
        const updatedListMachines = [...state.machinesList];
        updatedListMachines[index] = {
          ...updatedListMachines[index],
          ...action.payload.data,
        };

        return {
          ...state,
          machinesList: updatedListMachines,
        };
      }

      return state;
    });

    builder.addCase(findMachinesApi.fulfilled, (state, action) => {
      const groupedByOdfs = _.groupBy(action.payload, "odf_code");
      const groupedByCode = _.groupBy(action.payload, "code");

      return {
        ...state,
        machinesList: action.payload,
        ListMachinesPerOdfs: groupedByOdfs,
        machineListByCode: groupedByCode,
      };
    });
  },
});

export const ListMachines = (state: RootState) => state.machines.machinesList;
export const ListMachinesByCode = (state: RootState) =>
  state.machines.machineListByCode;

export const ListMachinesPerOdfs = (state: RootState) =>
  state.machines.ListMachinesPerOdfs;

export default machineSlice.reducer;
