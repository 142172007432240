import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { UserType } from "../../../Types/Entites/User";

export const login = createAsyncThunk(
  "users/login",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post("users/login", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface auth {
  auth: { user: UserType; token: string } | null;
}

const initialState: auth = {
  auth: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      return {
        ...state,
        auth: initialState.auth,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.auth = action.payload;
    });
  },
});
export const { logout } = authSlice.actions;

export const Auth = (state: RootState) => state.auth.auth;
export const currentUser = (state: RootState) => state.auth.auth?.user;

export default authSlice.reducer;
