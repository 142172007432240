import React, { useState } from "react";
import axios from "axios";

function FileInput({ type, reload, setReload }: any) {
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      setLoading(true); // Set loading to true during upload

      // Upload the file to the backend
      const uploadResponse = await axios.post("progs/uploadExcel", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (uploadResponse.data.success) {
        setFileName(file.name); // Set the file name
        const fileName = uploadResponse.data.fileName;
        await axios.post("progs/insertFromExcel" + type, {
          fileName,
        });

        // Reset file input after 3 seconds
        setTimeout(() => {
          setFileName("");
          setLoading(false);
        }, 2000);
      }
      setReload(!reload);
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false); // Reset loading state on error
    }
  };

  return (
    <div className="file-input-wrapper">
      <input type="file" onChange={handleFileUpload} disabled={loading} />
      <label>
        {loading ? (
          <i
            className="fas fa-spinner fa-spin"
            style={{ marginRight: "5px" }}
          ></i>
        ) : (
          <i className="fas fa-file-import" style={{ marginRight: "5px" }}></i>
        )}
        {loading ? "Uploading..." : fileName ? fileName : "Import"}
      </label>
    </div>
  );
}

export default FileInput;
