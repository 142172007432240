import React, { useEffect, useRef, useState } from "react";
import { ChecklistType } from "../../_App/Types/Entites/ChecklistType";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  affectationChecklistByMachineGroup,
  checklistsPreventifGroupedByGroupe,
  findAffectationChecklist,
  findAllChecklists,
  findChecklistResultByPlaning,
  PreventifChecklistResults,
} from "../../_App/Redux/Slices/checklists/checklistSlice";
import { findMachineById } from "../../_App/Redux/Slices/machines/machineSlice";
import { MachineType } from "../../_App/Types/Entites/MachineType";
import Loader from "../../_Layouts/Loader/Loader";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";

const ListChecklistsPreventif = () => {
  const { planing, machineCode } = useParams();
  const [machine, setMachine] = useState<MachineType>();
  const componentRef = useRef<HTMLDivElement>(null);

  const handlePrint = () => {
    // Access the hidden iframe and trigger its print method
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };

  function extractTime(timestamp: any) {
    const date = new Date(timestamp);

    // Extracting time components
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const seconds = String(date.getUTCSeconds()).padStart(2, "0");

    // Formatting time as string
    const time = `${hours}:${minutes}:${seconds}`;

    return time;
  }

  const [loading, setLoading] = useState(true);

  const dispatch = useAppDispatch();
  const results = useAppSelector(PreventifChecklistResults);
  const affectations = useAppSelector(affectationChecklistByMachineGroup);

  const checklists = useAppSelector(checklistsPreventifGroupedByGroupe);

  const horaires = () => {
    if (
      machine &&
      affectations &&
      Object.keys(affectations).length > 0 &&
      Object.keys(checklists).length > 0 &&
      machine.groupe in affectations
    ) {
      for (const affectation of affectations[machine.groupe]) {
        if (affectation.groupe_checklist in checklists) {
          for (const checklist of checklists[affectation.groupe_checklist]) {
            if (
              checklist.ChecklistResults &&
              checklist.ChecklistResults.length > 0 &&
              checklist.ChecklistResults[0].date
            ) {
              return {
                date: checklist.ChecklistResults[0].date,
                heure: checklist.ChecklistResults[0].heure_debut,
              };
            }
          }
        }
      }
    }
    return { date: "", heure: "" };
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (machineCode && planing) {
          setLoading(true);
          const resMachine = await dispatch(findMachineById(machineCode));
          const resAff = await dispatch(findAffectationChecklist(null));

          if (resMachine.payload) {
            setMachine(resMachine.payload);
            dispatch(findAllChecklists(null));
            dispatch(findChecklistResultByPlaning(planing));
          }
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, machineCode]);
  // const whatever = () => {
  //   for (const group in affectations) {
  //     affectations[group].forEach((affectation) => {
  //       if (affectation.groupe_checklist in checklists) {
  //         checklists[affectation.groupe_checklist].forEach((item) => {
  //           if (item.ChecklistResults && item.ChecklistResults[0]) {
  //             return item.ChecklistResults[0].date;
  //           }
  //         });
  //       }
  //     });
  //   }
  // };
  return (
    <div>
      {!loading &&
      machine &&
      affectations &&
      Object.keys(affectations).length > 0 &&
      Object.keys(checklists).length > 0 &&
      machine.groupe in affectations ? (
        <div id="tableFooter" className="col-lg-12 col-12 layout-spacing">
          <div className="statbox widget box box-shadow">
            <div className="widget-header">
              <div className="row">
                <div
                  className="col-xl-12 col-md-12 col-sm-12 col-12"
                  style={{ padding: "0px 4px" }}
                  ref={componentRef}
                >
                  <table className="table table-bordered table-hover table-condensed mb-0 mt-0">
                    <thead>
                      <tr>
                        <th rowSpan={2}>
                          <img
                            style={{ height: "60px" }}
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/assets/img/ecopack_logo.png"
                            }
                            alt=""
                          />
                        </th>
                        <th
                          colSpan={2}
                          rowSpan={2}
                          style={{ height: "4rem", lineHeight: "4rem" }}
                        >
                          Fiche d'intervention préventive
                        </th>
                        <th>code</th>
                      </tr>
                      <tr>
                        <th>{new Date().toDateString()} </th>
                      </tr>
                      <tr>
                        <th>Date</th>
                        <th>Machine : {machine.numero_serie}</th>
                        <th colSpan={2}>Technicien</th>
                      </tr>
                      <tr>
                        <th
                          style={{
                            textAlign: "center",
                            borderRight: "1px solid #ebedf2",
                          }}
                        >
                          Operation
                        </th>
                        <th
                          style={{
                            textAlign: "center",
                            borderRight: "1px solid #ebedf2",
                          }}
                        >
                          Action
                        </th>
                        <th style={{ textAlign: "center" }} colSpan={2}>
                          Observation
                        </th>
                      </tr>
                    </thead>
                    {machine &&
                      affectations &&
                      Object.keys(affectations).length > 0 &&
                      Object.keys(checklists).length > 0 &&
                      machine.groupe in affectations &&
                      affectations[machine.groupe].map(
                        (affectation, index1) => (
                          <React.Fragment key={index1}>
                            {checklists[affectation.groupe_checklist] ? (
                              <thead>
                                <tr>
                                  <th
                                    colSpan={4}
                                    style={{ textAlign: "center" }}
                                  >
                                    {" "}
                                    {affectation.groupe_checklist}{" "}
                                  </th>
                                </tr>
                              </thead>
                            ) : null}
                            <tbody>
                              {affectation.groupe_checklist in checklists &&
                                checklists[affectation.groupe_checklist].map(
                                  (item, index) => (
                                    <tr key={index}>
                                      <td
                                        style={{
                                          borderRight: "1px solid #ebedf2",
                                        }}
                                      >
                                        {item.libelle}
                                      </td>
                                      <td
                                        style={{
                                          borderRight: "1px solid #ebedf2",
                                        }}
                                        className="text-center"
                                      >
                                        <ul className="table-controls">
                                          {results &&
                                          results[item.code] &&
                                          results[item.code][0] ? (
                                            results[item.code][0].result ===
                                            "1" ? (
                                              results[item.code].some(
                                                (item) => item.result === "0"
                                              ) ? (
                                                <>
                                                  <li
                                                    data-tooltip-id={`my-tooltip-${index}`}
                                                  >
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-x-circle text-danger"
                                                      >
                                                        <circle
                                                          cx="12"
                                                          cy="12"
                                                          r="10"
                                                        ></circle>
                                                        <line
                                                          x1="15"
                                                          y1="9"
                                                          x2="9"
                                                          y2="15"
                                                        ></line>
                                                        <line
                                                          x1="9"
                                                          y1="9"
                                                          x2="15"
                                                          y2="15"
                                                        ></line>
                                                      </svg>
                                                    </span>
                                                  </li>
                                                  <li>
                                                    <span>
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        className="feather feather-check-circle text-success"
                                                      >
                                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                      </svg>
                                                    </span>
                                                  </li>
                                                  <ReactTooltip
                                                    id={`my-tooltip-${index}`}
                                                    place="right"
                                                    style={{
                                                      backgroundColor: "#fff",
                                                      color: "#222",
                                                    }}
                                                  >
                                                    <div className="mt-container mx-auto">
                                                      <div className="timeline-alter">
                                                        {results[item.code].map(
                                                          (result, index2) => (
                                                            <div
                                                              className="item-timeline"
                                                              key={index2}
                                                            >
                                                              <div className="t-time">
                                                                <p className="">
                                                                  {
                                                                    result.heure_debut
                                                                  }
                                                                </p>
                                                              </div>
                                                              <div className="t-img">
                                                                {result.result ===
                                                                "1" ? (
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-check-circle text-success"
                                                                  >
                                                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                                  </svg>
                                                                ) : (
                                                                  <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    className="feather feather-x-circle text-danger"
                                                                  >
                                                                    <circle
                                                                      cx="12"
                                                                      cy="12"
                                                                      r="10"
                                                                    ></circle>
                                                                    <line
                                                                      x1="15"
                                                                      y1="9"
                                                                      x2="9"
                                                                      y2="15"
                                                                    ></line>
                                                                    <line
                                                                      x1="9"
                                                                      y1="9"
                                                                      x2="15"
                                                                      y2="15"
                                                                    ></line>
                                                                  </svg>
                                                                )}
                                                              </div>
                                                              {/* <div className="t-meta-time">
                                                            <p className="">
                                                              
                                                            </p>
                                                          </div> */}

                                                              <div className="t-text">
                                                                <p>
                                                                  {" "}
                                                                  {
                                                                    result.motif
                                                                  }{" "}
                                                                </p>
                                                              </div>
                                                            </div>
                                                          )
                                                        )}
                                                      </div>
                                                    </div>
                                                  </ReactTooltip>
                                                </>
                                              ) : (
                                                <li>
                                                  <span>
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="24"
                                                      height="24"
                                                      viewBox="0 0 24 24"
                                                      fill="none"
                                                      stroke="currentColor"
                                                      strokeWidth="2"
                                                      strokeLinecap="round"
                                                      strokeLinejoin="round"
                                                      className="feather feather-check-circle text-success"
                                                    >
                                                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                      <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                    </svg>
                                                  </span>
                                                </li>
                                              )
                                            ) : (
                                              <li>
                                                <span>
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    className="feather feather-x-circle text-danger"
                                                  >
                                                    <circle
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                    ></circle>
                                                    <line
                                                      x1="15"
                                                      y1="9"
                                                      x2="9"
                                                      y2="15"
                                                    ></line>
                                                    <line
                                                      x1="9"
                                                      y1="9"
                                                      x2="15"
                                                      y2="15"
                                                    ></line>
                                                  </svg>
                                                </span>
                                              </li>
                                            )
                                          ) : null}
                                        </ul>
                                      </td>
                                      <td className="text-center" colSpan={2}>
                                        {" "}
                                        {results &&
                                        results[item.code] &&
                                        results[item.code][0]
                                          ? results[item.code][0].commentaire
                                          : ""}{" "}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </React.Fragment>
                        )
                      )}
                    <tfoot>
                      <tr>
                        <th colSpan={4}>
                          Autres actions :<hr className="dot-line"></hr>{" "}
                          <hr className="dot-line"></hr>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={4}>
                          Observation du responsable maintenance aprés
                          intervention :<hr className="dot-line"></hr>{" "}
                          <hr className="dot-line"></hr>
                        </th>
                      </tr>
                      <tr>
                        <th rowSpan={2}>
                          Vérifier l'état de propreté de la machine après
                          intervention et que tous les outils de travail sont
                          éliminés et rangés
                        </th>
                        <th
                          rowSpan={2}
                          style={{ height: "4rem", lineHeight: "4rem" }}
                        >
                          {" "}
                          <i className="far fa-square"></i> Oui{" "}
                        </th>
                        <th
                          rowSpan={2}
                          style={{ height: "4rem", lineHeight: "4rem" }}
                        >
                          <i className="far fa-square"></i> Non
                        </th>
                        <th
                          style={{
                            padding: "2px",
                            height: "20px",
                            textAlign: "center",
                          }}
                        >
                          Obesrvations
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                      </tr>
                      <tr>
                        <th>Visa technicien</th>
                        <th colSpan={2}>Date fin de l'intervention </th>
                        <th>Visa responsable maintenance</th>
                      </tr>

                      <tr>
                        <th style={{ color: "#fff", border: "1px solid #000" }}>
                          ............
                        </th>
                        <th
                          colSpan={2}
                          style={{ color: "#fff", border: "1px solid #000" }}
                        >
                          ............
                        </th>
                        <th style={{ color: "#fff", border: "1px solid #000" }}>
                          ............
                        </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>{" "}
          </div>
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                onClick={handlePrint}
                className="btn-info"
                style={{ float: "right" }}
              >
                Print
              </Button>
            )}
            content={() => componentRef.current}
          />
          {/* Hidden iframe for printing */}
          <iframe
            id="print-frame"
            title="print-frame"
            style={{ display: "none" }}
            onLoad={() => handlePrint()}
          />
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default ListChecklistsPreventif;
